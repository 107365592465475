import { Injectable } from '@angular/core';
import { Adapter } from 'src/app/core/adapter';
import { Model } from 'src/app/core/models/model';

export class Token implements Model {
    constructor(
        public access_token: string,
        public refresh_token: string,
        public expires_in: number
    ) { }

    public storeToken() {
        localStorage.setItem('token', this.access_token);
    }

    public storeRefresToken() {
        localStorage.setItem('refresh_token', this.refresh_token);
    }
}

@Injectable({
    providedIn: 'root'
})
export class TokenAdapter implements Adapter<Token> {

    adapt(item: any): Token {
        return new Token(
            item.access_token,
            item.refresh_token,
            item.expires_in
        );
    }
}