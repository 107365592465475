<div class="login-page">
    <hydrao-admin-bar></hydrao-admin-bar>
    <div class="logo-container">
        <div class="hydrao-logo">
            <img src="assets/logo-smart.svg" />
        </div>
    </div>


    <app-login></app-login>

    <div class="version"> v{{version}}&nbsp;-&nbsp;{{hash}}</div>
</div>