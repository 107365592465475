import { Component } from '@angular/core';
import { VERSION } from '../../../../environments/version';


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {

  public get version () {
    return VERSION.version;
  }

  public get hash () {
    return VERSION.hash;
  }

  public get branch () {
    return VERSION.branch;
  }

  constructor() { }
}
