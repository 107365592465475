import { Injectable } from '@angular/core';
import { DateTime, Settings } from 'luxon';
import { NGXLogger } from 'ngx-logger';
import { Adapter } from 'src/app/core/adapter';
import { Model } from 'src/app/core/models/model';
import { Threshold, ThresholdAdapter } from 'src/app/core/models/threshold.model';

export class ShowerHead implements Model {
    constructor(
        public device_uuid: string,
        public first_seen: DateTime,
        public last_seen: DateTime,
        public threshold: Threshold[],
        public threshold_request: Threshold[],
        public label: string,
        public serial: string,
        public type: string,
        public connectivity: string,
        public hw_version: string,
        public fw_version: string,
        public place_id: number

    ) { }


    public get thresholdPending(): boolean {
        if (!this.threshold && this.threshold_request) return true;
        if (this.threshold && this.threshold_request && Array.isArray(this.threshold) && Array.isArray(this.threshold_request)) {
            let sortedThreshold = this.threshold.sort((t1, t2) => t1.liter - t2.liter);
            let sortedThresholdRequest = this.threshold_request.sort((t1, t2) => t1.liter - t2.liter);

            for (let i = 0; i < sortedThreshold.length; i++) {
                if (!sortedThreshold[i].equals(sortedThresholdRequest[i])) {
                    return true;
                }
            }
            return false;
        }
        return false;
    }

    public get isBLE(): boolean {
        return this.connectivity == 'BLE';
    }

    public get isLoRa(): boolean {
        return this.connectivity == 'LoRa';
    }

    public get isAloe(): boolean {
        return this.type == 'aloe';
    }
    public get isYucca(): boolean {
        return this.type == 'yucca';
    }
    public get isFirst(): boolean {
        return this.type == 'first' || this.type == 'origin';
    }
    public get isCereus(): boolean {
        return this.type == 'cereus';
    }

    public get isTypeUnknown(): boolean {
        return this.type == 'unknown' || !this.type;
    }

    public get universalLabel() : string {
        if(this.label && this.label.trim() !== '') return this.label;
        if(this.serial && this.serial.trim() !== '') return this.serial;
        return this.device_uuid;
    }

}



@Injectable({
    providedIn: 'root'
})
export class ShowerHeadAdapter implements Adapter<ShowerHead> {
    constructor(private thresholdAdapter: ThresholdAdapter, private logger: NGXLogger) { }

    adapt(item: any): ShowerHead {
        let threshold = null;
        let threshold_request = null;
        try {
            threshold = JSON.parse(item.threshold);
        }
        catch (e) {
            this.logger.error('wrong JSON for threshold on sh ' + item.device_uuid);
        }
        try {
            threshold_request = JSON.parse(item.threshold_request);
        }
        catch (e) {
            this.logger.error('wrong JSON for threshold request on sh ' + item.device_uuid);
        }

        return new ShowerHead(
            item.device_uuid,
            DateTime.fromISO(item.first_seen, { zone: Settings.defaultZone }),
            DateTime.fromISO(item.last_seen, { zone: Settings.defaultZone }),
            Array.isArray(threshold) ? threshold.map(i => this.thresholdAdapter.adapt(i)) : null,
            Array.isArray(threshold_request) ? threshold_request.map(i => this.thresholdAdapter.adapt(i)) : null,
            item.label,
            item.serial,
            item.type,
            item.connectivity,
            item.hw_version,
            item.fw_version,
            item.place_id
        );



    }
}