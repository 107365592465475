<mat-card appearance="outlined" class="mat-elevation-z0">
    <div class="hydrao-card-title" i18n>First connection</div>

    <div class="intro-text" i18n>
        Welcome to My HYDRAO ! Let's take some time to configure your account.
    </div>
    <div class="select-device" *ngIf="dispDevice">
        <p i18n> What kind of device do you have ?</p>
        <mat-checkbox color="primary" (change)="onShCheckChange($event)" i18n>HYDRAO Shower Head</mat-checkbox>
        <mat-checkbox color="primary" (change)="onMeterCheckChange($event)" i18n>HYDRAO Meter</mat-checkbox>
    </div>
    <div class="select-type" *ngIf="dispType">
        <p i18n>Are you a professional ?</p>

        <mat-radio-group (change)="onTypeChange($event)">
            <mat-radio-button color="primary" value="places" i18n>
                Yes, i'm a professional, and i want to manage places (building, hotels, ...)
            </mat-radio-button>
            <mat-radio-button color="primary" value="no_places" i18n>
                No i'm not
            </mat-radio-button>
            <!-- <mat-radio-button color="primary" value="threshold_alert"  [checked]="metric == 'hourly_profile'" i18n>
                hour consumption
            </mat-radio-button> -->
        </mat-radio-group>
    </div>
    <div class="buttons">
        <button *ngIf="!loading" mat-flat-button color="primary" [disabled]="!nextEnabled" (click)="nextClicked()" i18n>
            next
        </button>
        <mat-progress-spinner diameter="20" strokeWidth="1" *ngIf="loading" mode="indeterminate">
        </mat-progress-spinner>
    </div>

</mat-card>