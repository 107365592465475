import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { User } from 'src/app/core/models/user.model';
import { CacheService } from 'src/app/core/services/core/cache.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'hydrao-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent {


  public nextEnabled: boolean = false;
  public dispDevice: boolean = true;
  public dispType: boolean = false;
  public loading: boolean = false;


  private _user: User;

  constructor(cacheSvc: CacheService, private _userSvc: UserService, private _router: Router) {

    this._user = cacheSvc.inMemoryData.get('user') as User;
    this._userSvc.getUserData().subscribe((u) => this._user = u);
  }

  public onTypeChange(event: MatRadioChange) {
    if (event.value == 'places') {
      this._user.enablePlaceFeature();
      this.nextEnabled = true;
    } else if (event.value == 'no_places') {
      this._user.disablePlaceFeature();
      this.nextEnabled = true;
    }
  }

  public onShCheckChange(event: MatCheckboxChange) {
    if (event.checked) {
      this._user.enableShowerHeadFeature();
    } else {
      this._user.disableShowerHeadFeature();
    }

    this.nextEnabled = this._user.canViewMeters() || this._user.canViewSh();

  }

  public onMeterCheckChange(event: MatCheckboxChange) {
    if (event.checked) {
      this._user.enableMeterFeature();
    } else {
      this._user.disableMeterFeature();
    }
    this.nextEnabled = this._user.canViewMeters() || this._user.canViewSh();
  }

  public nextClicked() {
    if (this.dispDevice && !this.dispType && this._user.canViewSh() && !this._user.canViewPlaces()) {
      //display type 
      this.dispDevice = false;
      this.dispType = true;
      this.nextEnabled = false;
    } else {
      this.loading = true;
      this._userSvc.saveUserData(this._user).subscribe(() => this._router.navigate(['dashboard'], { replaceUrl: true }));
    }

  }

}
