import { Component } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, NavigationEnd } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { HydraoLogMonitor } from 'src/app/core/log-monitor';

declare let gtag: Function;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    router: Router,
    private _logger: NGXLogger,
    logMonitor: HydraoLogMonitor
  ) {



    this._logger.registerMonitor(logMonitor);
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-51707725-4',
          {
            'page_path': event.urlAfterRedirects,
            'cookie_flags': 'SameSite=None;Secure'
          }
        );
      }
    });

    this._logger.info('[APP] ================== App loaded ==================');



    matIconRegistry.addSvgIcon(
      'flag_french',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/flags/fr.svg')
    );
    matIconRegistry.addSvgIcon(
      'flag_usa',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/flags/us.svg')
    );

    matIconRegistry.addSvgIcon(
      'cereus',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/cereus.svg')
    );

    matIconRegistry.addSvgIcon(
      'aloe',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/aloe.svg')
    );

    matIconRegistry.addSvgIcon(
      'yucca',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/yucca.svg')
    );

    matIconRegistry.addSvgIcon(
      'first',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/first.svg')
    );
    matIconRegistry.addSvgIcon(
      'meter',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/meter.svg')
    );

    matIconRegistry.addSvgIcon(
      'chrono',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/chrono.svg')
    );
    matIconRegistry.addSvgIcon(
      'drop',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/drop_fat.svg')
    );
    matIconRegistry.addSvgIcon(
      'shower',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/shower.svg')
    );
    matIconRegistry.addSvgIcon(
      'water_usage',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/water_usage.svg')
    );

    matIconRegistry.addSvgIcon(
      'shower_head',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/shower_head.svg')
    );

    matIconRegistry.addSvgIcon(
      'account_authorization_pending',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/account/account_authorization_pending.svg')
    );

    matIconRegistry.addSvgIcon(
      'account_active',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/account/account_active.svg')
    );

    matIconRegistry.addSvgIcon(
      'account_authorization_refused',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/account/account_authorization_refused.svg')
    );

    matIconRegistry.addSvgIcon(
      'account_creation_pending',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/account/account_creation_pending.svg')
    );

    matIconRegistry.addSvgIcon(
      'lora',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/lora.svg')
    );

    matIconRegistry.addSvgIcon(
      'smart_alert',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/smart_alert.svg')
    );

    matIconRegistry.addSvgIcon(
      'threshold_alert',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/threshold_alert.svg')
    );


    matIconRegistry.addSvgIcon(
      'hydrao_connect',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/hydrao-connect.svg')
    );


  }


}
