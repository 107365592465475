<div class="form-wrapper">
    <mat-card appearance="outlined">
        <div class="hydrao-card-title" i18n>Create new account</div>
        <form [formGroup]="registerForm" autocomplete="off" novalidate (ngSubmit)="register(registerForm.value)"
            fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
            <mat-form-field>
                <input required matInput i18n-placeholder placeholder="email" type="email" formControlName="email"
                    id="email" autofocus>
                <mat-error *ngIf="hasError('email', 'required')" i18n>Email is required</mat-error>
                <mat-error *ngIf="hasError('email', 'email')" i18n>Please enter a valid email</mat-error>
            </mat-form-field>
            <mat-form-field>
                <input required matInput i18n-placeholder placeholder="password" type="password" formControlName="pass"
                    id="pass">
                <mat-error *ngIf="hasError('pass', 'required')" i18n>Password is required</mat-error>
                <mat-error *ngIf="hasError('pass', 'minlength')" i18n>Password length is at least 8 characters
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <input required matInput i18n-placeholder placeholder="password confirmation" type="password"
                    formControlName="passConf" id="passConf">
                <mat-error *ngIf="hasError('passConf', 'required')" i18n>Password confirmation is required</mat-error>
                <mat-error *ngIf="hasError('passConf', 'mustMatch')" i18n>Password confirmation does not match
                </mat-error>
            </mat-form-field>

            <div class="buttons">
                <button mat-button type="button" color="primary" i18n (click)="goBack()">Go Back</button>
                <button mat-flat-button color="primary" [disabled]="!registerForm.valid" i18n>register</button>
            </div>
            <mat-error class="error" *ngIf="errorCode" [ngSwitch]="errorCode">
                <div *ngSwitchCase="'UserAlreadyExists'" i18n>
                    User already exists
                </div>
                <div *ngSwitchDefault i18n>
                    Unexpected error, please try again
                </div>
            </mat-error>
        </form>
    </mat-card>
</div>