import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';

import { map, catchError, retryWhen, tap } from 'rxjs/operators';
import { throwError, Observable, defer } from 'rxjs';

import { HttpErrorAdapter, ErrorCode } from 'src/app/core/models/error.model';
import { NGXLogger } from 'ngx-logger';
import { MatSnackBar } from '@angular/material/snack-bar';
import { API_BASE_URL } from 'src/api.conf';
import { Model } from 'src/app/core/models/model';
import { Adapter } from 'src/app/core/adapter';
import { HydraoResponse } from 'src/app/core/models/response.model';
import { CacheService } from 'src/app/core/services/core/cache.service';
import { DateTime } from 'luxon';



const LOG_TAG = '[API]';

export class ResponseWithHeaders<T extends Model> {
    constructor(public data: T, public headers: HttpHeaders) { }
}


@Injectable({ providedIn: 'root' })
export abstract class HydraoApiServiceBase {
    protected httpClient: HttpClient;
    protected snackBar: MatSnackBar;
    protected logger: NGXLogger;
    protected errorAdapter: HttpErrorAdapter;


    constructor(injector: Injector) {
        this.httpClient = injector.get(HttpClient);
        this.snackBar = injector.get(MatSnackBar);
        this.logger = injector.get(NGXLogger);
        this.errorAdapter = injector.get(HttpErrorAdapter);
    }

    protected execQuery(request: Observable<any>, dispSnack: boolean = true): Observable<any> {
        return defer(() => {
            this.snackBar.dismiss(); //clear snackbar before any request
     

            return request
                .pipe(catchError(err => {
                    this.logger.warn(LOG_TAG, 'error during request after' + (err && err.message ? err.message : JSON.stringify(err)));
                    return throwError(() => this.errorAdapter.adapt(err));
                    //return throwError(this.errorAdapter.adapt(err));
                }))
                .pipe(
                    retryWhen(err => {
                        let retries = 0;
                        return err.pipe(
                            // delay(1000),
                            map(error => {
                                if (error['http_status'] == 404 || error['http_status'] == 401) {
                                    this.logger.info('404 or 401 error, do not retry');
                                    throw error;
                                }
                                if (retries++ === 2) {
                                    throw error;
                                }
                                this.logger.info('retry #' + retries);
                                return error;
                            })
                        );
                    })
                )
                .pipe(catchError(err => {
                    this.logger.warn('fatal fail ' + err.http_status);
                    if (dispSnack) {

                        if (err.error_code && err.error_code == 'UnexpectedError') {
                            this.snackBar.open($localize`unexpected error`, $localize`RETRY`, {
                                panelClass: ['error-snackbar']
                            }).onAction().subscribe(() => {
                                document.location.reload();
                            });
                        } else if (!err.error_code || err.error_code === ErrorCode.Unexpected) {
                            this.snackBar.open($localize`unexpected error`, $localize`RETRY`, {
                                panelClass: ['error-snackbar']
                            }).onAction().subscribe(() => {
                                document.location.reload();
                            });
                        } else {
                            this.snackBar.open(err.message, $localize`OK`, {
                                panelClass: ['error-snackbar']
                            });
                        }
                    }

                    throw err;
                }));
        });


    }

    protected delete(path: string): Observable<any> {
        this.logger.info(`[DELETE] ${path}`);
        let beginTime: DateTime = DateTime.local();
        return this.execQuery(this.httpClient.delete(API_BASE_URL + path)).pipe(tap(() => {
            let duration: number = DateTime.local().diff(beginTime).milliseconds;
            this.logger.info(LOG_TAG, `[DELETE][${duration}ms]${path}`);
        }));
    }

    protected rawPut(path: string, data: any, silently = false): Observable<any> {
        this.logger.info(`[RAW_PUT] ${path}`);
        let beginTime: DateTime = DateTime.local();

        let req = this.httpClient.put(API_BASE_URL + path, data);
        return this.execQuery(req, !silently).pipe(tap(() => {
            let duration: number = DateTime.local().diff(beginTime).milliseconds;
            this.logger.info(LOG_TAG, `[PUT][${duration}ms]${path} raw`);
        }));;
    }



}



@Injectable({ providedIn: 'root' })
export abstract class HydraoApiService<T extends Model> extends HydraoApiServiceBase {

    protected cacheService: CacheService


    constructor(injector: Injector) {
        super(injector);
        this.cacheService = injector.get(CacheService);

    }


    protected getWithHeaders(path: string, adapter: Adapter<T>,  disableCache = false): Observable<ResponseWithHeaders<T | T[]>> {
        let beginTime: DateTime = DateTime.local();
        this.logger.info(`[GET][WITH_HEADERS] ${path}`);

        let query = this.httpClient.get(API_BASE_URL + path, { observe: 'response' }).pipe(map((r: HttpResponse<T>) => {
            let headerMap = {};
            for (let hkey of r.headers.keys()) {
                headerMap[hkey] = r.headers.get(hkey);
            }
            return {
                body: r.body,
                headers: headerMap
            }
        }));

        let wrappedQuery = this.execQuery(query, true)
            .pipe(tap(() => {
                let duration: number = DateTime.local().diff(beginTime).milliseconds;
                this.logger.info(LOG_TAG, `[GET][${duration}ms]${path} with headers`);
            }));

        if (!disableCache) query = this.cacheService.get(path, wrappedQuery);

        return wrappedQuery.pipe(
            // Adapt each item in the raw data array
            map((resp: any) => {
                //resp.headers;
                if (Array.isArray(resp.body)) {

                    return new ResponseWithHeaders(resp.body.map(item => adapter.adapt(item)), new HttpHeaders(resp.headers));
                } else if (resp.body) {
                    return new ResponseWithHeaders(adapter.adapt(resp.body), new HttpHeaders(resp.headers));
                }
                return null;

            }));


    }



    protected get(path: string, adapter: Adapter<T>,  dispSnack: boolean = true, disableCache: boolean = false, refreshDelay: number = -1): Observable<T | T[] | any> {
        this.logger.info(`[GET] ${path}`);
        let query = this.httpClient.get(API_BASE_URL + path);
        // 
        let beginTime: DateTime = DateTime.local();

        let wrappedQuery = this.execQuery(query, dispSnack)
            .pipe(tap(() => {
                let duration: number = DateTime.local().diff(beginTime).milliseconds;
                this.logger.info(LOG_TAG, `[GET][${duration}ms]${path}`);
            }));

        if (!disableCache) wrappedQuery = this.cacheService.get(path, wrappedQuery, refreshDelay >= 0 ? refreshDelay : -1);

        return wrappedQuery.pipe(
            // Adapt each item in the raw data array
            map((data: any) => {

                if (adapter) {
                    if (Array.isArray(data)) {
                        return data.map(item => adapter.adapt(item));
                    } else if (data) {
                        return adapter.adapt(data);
                    }
                } else {
                    return data;
                }

                return null;

            })
        );
    }

    protected post(path: string, data: T, adapter: Adapter<T>): Observable<T> {
        this.logger.info(`[POST] ${path}`);
        let beginTime: DateTime = DateTime.local();

        let req = this.httpClient.post<T>(API_BASE_URL + path, data);
        if (adapter) {
            req = req.pipe(
                // Adapt each item in the raw data array
                map((data: any) => adapter.adapt(data))
            );
        }
        return this.execQuery(req).pipe(tap(() => {
            let duration: number = DateTime.local().diff(beginTime).milliseconds;
            this.logger.info(LOG_TAG, `[POST][${duration}ms]${path}`);
        }));
    }

    protected postResponse(path: string, data: any, adapter: Adapter<HydraoResponse>): Observable<HydraoResponse> {
        this.logger.info(`[POST][RESPONSE] ${path}`);
        let beginTime: DateTime = DateTime.local();

        let req = this.httpClient.post<HydraoResponse>(API_BASE_URL + path, data);
        if (adapter) {
            req = req.pipe(
                // Adapt each item in the raw data array
                map((data: any) => adapter.adapt(data))
            );
        }
        return this.execQuery(req, false).pipe(tap(() => {
            let duration: number = DateTime.local().diff(beginTime).milliseconds;
            this.logger.info(LOG_TAG, `[POST][${duration}ms]${path}`);
        }));
    }

    protected put(path: string, data: any, adapter: Adapter<T>): Observable<T> {
        this.logger.info(`[PUT] ${path}`);
        let beginTime: DateTime = DateTime.local();

        let req = this.httpClient.put<T>(API_BASE_URL + path, data);
        if (adapter) {
            req = req.pipe(
                // Adapt each item in the raw data array
                map((data: any) => adapter.adapt(data))
            );
        }
        return this.execQuery(req, true).pipe(tap(() => {
            let duration: number = DateTime.local().diff(beginTime).milliseconds;
            this.logger.info(LOG_TAG, `[PUT][${duration}ms]${path}`);
        }));
    }


    protected patch(path: string, data: any, adapter: Adapter<T>): Observable<T> {
        this.logger.info(`[PATCH] ${path}`);
        let beginTime: DateTime = DateTime.local();

        let req = this.httpClient.patch<T>(API_BASE_URL + path, data);
        if (adapter) {
            req = req.pipe(
                // Adapt each item in the raw data array
                map((data: any) => adapter.adapt(data))
            );
        }
        return this.execQuery(req, true).pipe(tap(() => {
            let duration: number = DateTime.local().diff(beginTime).milliseconds;
            this.logger.info(LOG_TAG, `[PATCH][${duration}ms]${path}`);
        }));
    }

    protected putResponse(path: string, data: any, adapter: Adapter<HydraoResponse>): Observable<HydraoResponse> {
        this.logger.info(`[PUT][RESPONSE] ${path}`);
        let beginTime: DateTime = DateTime.local();

        let req = this.httpClient.put<HydraoResponse>(API_BASE_URL + path, data);
        if (adapter) {
            req = req.pipe(
                // Adapt each item in the raw data array
                map((data: any) => adapter.adapt(data))
            );
        }
        return this.execQuery(req, false).pipe(tap(() => {
            let duration: number = DateTime.local().diff(beginTime).milliseconds;
            this.logger.info(LOG_TAG, `[PUT][${duration}ms]${path}`);
        }));
    }




}
