import { ErrorHandler, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private _logger: NGXLogger) {

    }
  
  public handleError(error:any) {
    // your custom error handling logic  
    if(error.message) this._logger.error('[GLOBAL_ERROR]', error.message);
    if(error.stack) this._logger.error('[GLOBAL_ERROR]', error.stack);
    if(!error.message) this._logger.error('[GLOBAL_ERROR]', JSON.stringify(error));

  }
}