<div class="form-wrapper">
    <mat-card appearance="outlined">
        <div class="hydrao-card-title" ><span i18n>Recover password</span> (1/2)</div>
        <form [formGroup]="forgotPassForm" autocomplete="off" novalidate (ngSubmit)="submit(forgotPassForm.value)"
            fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
            <mat-form-field>
                <input required matInput i18n-placeholder placeholder="email" type="email" formControlName="email"
                    id="email" autofocus>
                <mat-error *ngIf="hasError('email', 'required')" i18n>Email is required</mat-error>
                <mat-error *ngIf="hasError('email', 'email')" i18n>Please enter a valid email</mat-error>
                <mat-error *ngIf="hasError('email', 'userNotFound')" i18n>User not found, please verify you email</mat-error>

            </mat-form-field>
          
            <div class="buttons" *ngIf="!loading">
                <button mat-button type="button" color="primary" i18n (click)="goBack()">Go Back</button>
                <button mat-flat-button color="primary" [disabled]="!forgotPassForm.valid" i18n>recover password</button>
            </div>
            <mat-progress-spinner diameter="20" strokeWidth="1" *ngIf="loading" mode="indeterminate">
            </mat-progress-spinner>
            <mat-error class="error" *ngIf="errorCode" [ngSwitch]="errorCode">
                <div *ngSwitchCase="'UserAlreadyExists'" i18n>
                    User already exists
                </div>
                <div *ngSwitchDefault i18n>
                    Unexpected error, please try again
                </div>
            </mat-error>
        </form>
    </mat-card>
</div>