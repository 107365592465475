import { Injectable } from '@angular/core';
import { DateTime, Duration } from 'luxon';
import { Adapter } from 'src/app/core/adapter';
import { AlertDefinition, AlertDefinitionAdapter } from 'src/app/core/models/alert-definition.model';
import { Meter, MeterAdapter } from 'src/app/core/models/meter.model';
import { Model } from 'src/app/core/models/model';
import { Place, PlaceAdapter } from 'src/app/core/models/place.model';
import { User } from 'src/app/core/models/user.model';
import { CacheService } from 'src/app/core/services/core/cache.service';
import { UserService } from 'src/app/core/services/user.service';
import { UtilsService } from 'src/app/shared/services/utils.service';



export enum AnomalyType {
    daily_profile = 'daily_profile',
    duration = 'duration',
    flow_profile = 'flow_profile',
    hourly_profile = 'hourly_profile',
    volume = 'volume',
    daily_volume = 'daily_volume',
    monthly_volume = 'monthly_volume',
    low_capacitor = 'low_capacitor',
    meter_offline = 'meter_offline',
    gateway_offline = 'gateway_offline'
}

export enum DeviceType {
    meter = 'meter',
    gateway = 'gateway'
}


export class AlertDevice implements Model {
    public get universalLabel(): string {
        let id: string;
        if (this.label && this.label.trim() !== '') id = this.label;
        else if (this.serial && this.serial.trim() !== '') id = this.serial;
        else id = this.uuid;

        let type: string;
        switch (this.type) {
            case DeviceType.gateway:
                type = $localize`gateway`;
                break;
            case DeviceType.meter:
                type = $localize`meter`
                break;
        }

        return `${type} ${id}`;

    }



    constructor(
        public uuid: string,
        public type: DeviceType,
        public label: string,
        public serial: string
    ) { }
}

export class Anomaly implements Model {


    public get begin_date_string() {
        return this.begin_date ? this.begin_date.toLocaleString(DateTime.DATETIME_MED) : '-';
    }
    public get end_date_string() {
        return this.end_date ? this.end_date.toLocaleString(DateTime.DATETIME_MED) : $localize`never`;
    }
    public get notified_on_string() {
        return this.notified_on ? this.notified_on.toLocaleString(DateTime.DATETIME_MED) : $localize`never`;
    }
    public get end_notified_on_string() {
        return this.end_notified_on ? this.end_notified_on.toLocaleString(DateTime.DATETIME_MED) : $localize`never`;
    }


    constructor(
        public id: number,
        public type: AnomalyType,
        public is_active: boolean,
        public begin_date: DateTime,
        public end_date: DateTime,
        public notified_on: DateTime,
        public end_notified_on: DateTime,
        public distance: number,
        public flow: number,
        public low_value: number,
        public high_value: number
    ) {

    }
}


export class Alert implements Model {


    public get isSmartAlert() {
        if (!this.anomaly) return false;
        return this.anomaly.type == AnomalyType.daily_profile
            || this.anomaly.type == AnomalyType.flow_profile
            || this.anomaly.type == AnomalyType.hourly_profile;
    }

    public get isThresholdAlert() {
        if (!this.anomaly) return false;
        return this.anomaly.type == AnomalyType.duration
            || this.anomaly.type == AnomalyType.volume
            || this.anomaly.type == AnomalyType.daily_volume
            || this.anomaly.type == AnomalyType.monthly_volume;
    }

    public get isDysfunctionAlert() {
        if (!this.anomaly || !this.device) return false;
        return this.device.type == DeviceType.gateway
            || this.anomaly.type == AnomalyType.low_capacitor
            || this.anomaly.type == AnomalyType.meter_offline
            || this.anomaly.type == AnomalyType.gateway_offline;
    }

    public get isDistanceVolume() {
        if (!this.anomaly) return false;
        return this.anomaly.type == AnomalyType.volume
            || this.anomaly.type == AnomalyType.daily_volume
            || this.anomaly.type == AnomalyType.monthly_volume;
    }

    public get isDistanceDuration() {
        if (!this.anomaly) return false;
        return this.anomaly.type == AnomalyType.duration;
    }

    public get confidence(): number {
        if (this.isSmartAlert) {

            let percent = ((this.anomaly.distance - this.anomaly.low_value) / (this.anomaly.high_value - this.anomaly.low_value)) * 100;
            if (percent < 0) percent = 0;
            if (percent > 100) percent = 100;
            // console.log(`${this.anomaly_low_value} < ${this.anomaly_distance} < ${this.anomaly_high_value} ==> ${percent}`);
            return percent;
        } else if (this.anomaly.type == AnomalyType.volume) {

            let percent = (this.anomaly.distance / 1000) * 100; //TODO : improve confidence calculation.
            if (percent > 100) percent = 100;
            return percent;

        } else if (this.anomaly.type == AnomalyType.duration) {
            let percent = (this.anomaly.distance / 3600) * 100; //TODO : improve confidence calculation.
            if (percent > 100) percent = 100;
            return percent;
        }
        return 0;
    }

    public get totalDurationString(): string {
        if (!this.isDistanceDuration) return '-';

        let totalDuration = this.anomaly.distance + this.definition.value;
        let duration: Duration = Duration.fromObject({ second: totalDuration }).shiftTo('hours', 'minutes', 'seconds');
        return duration.toFormat("hh'h' mm'm' ss's'");
    }

    public get totalVolumeString(): string {
        if (!this.isDistanceVolume) return '-';
        let user = this.getUser();
        if (!user) return '-';

        let vol = this._utilsSvc.getVolumeFormattedWithUser(user, this.anomaly.distance + this.definition.value);
        return `${vol.value} ${vol.unit}`;
    }

    public get label(): string {
        let user = this.getUser();
        if (this.device.type == DeviceType.gateway) {
            return $localize`gateway offline`;
        } else {
            switch (this.anomaly.type) {
                case AnomalyType.volume: {
                    if (!user) return '';
                    let vol = this._utilsSvc.getVolumeFormattedWithUser(user, this.anomaly.distance);
                    return $localize`volume threshold exceeded by ${vol.value} ${vol.unit}`;
                }
                case AnomalyType.duration:
                    let duration: Duration = Duration.fromObject({ second: this.anomaly.distance }).shiftTo('hours', 'minutes', 'seconds');
                    return $localize`duration threshold exceeded by ${duration.hours > 0 ? duration.toFormat("hh'h' mm'm' ss's'") : duration.minutes > 0 ? duration.toFormat("mm'm' ss's'") : duration.toFormat("ss's'")}`;
                case AnomalyType.daily_profile:
                    return $localize`abnormal consumption on day ${this.anomaly.begin_date.toLocaleString(DateTime.DATE_MED)}`;
                case AnomalyType.flow_profile:

                    if (this.anomaly.flow) {
                        let flow = this._utilsSvc.getVolumeFormattedWithUser(user, this.anomaly.flow);
                        return $localize`abnormal consumption at ${flow.value + ' ' + flow.unit}/min `;
                    } else {
                        return $localize`abnormal consumption at unknown flow`;
                    }
                case AnomalyType.hourly_profile:
                    return $localize`abnormal consumption on day ${this.anomaly.begin_date.toLocaleString(DateTime.DATE_MED)} at ${this.anomaly.begin_date.toFormat('hh')}`;
                case AnomalyType.daily_volume: {
                    if (!user) return '';
                    let vol = this._utilsSvc.getVolumeFormattedWithUser(user, this.anomaly.distance);
                    return $localize`24h volume exceeded by ${vol.value} ${vol.unit}`;
                }
                case AnomalyType.monthly_volume: {
                    if (!user) return '';
                    let vol = this._utilsSvc.getVolumeFormattedWithUser(user, this.anomaly.distance);
                    return $localize`30d volume exceeded by ${vol.value} ${vol.unit}`;
                }
                case AnomalyType.low_capacitor:
                    return $localize`low energy level on meter`;
                case AnomalyType.gateway_offline:
                    return $localize`gateway offline`;
                case AnomalyType.meter_offline:
                    return $localize`meter offline`;
                default:
                    return 'unknown';
            }
        }

    }



    constructor(
        public getUser: () => User,
        private _utilsSvc: UtilsService,
        public anomaly: Anomaly,
        public definition: AlertDefinition,
        public device: AlertDevice,
        public place: Place,
        // public anomaly_id: number,
        // public device_uuid: String,
        // public meter_serial: String,
        // public meter_label: String,
        // public anomaly_begin_date: DateTime,
        // public anomaly_end_date: DateTime,
        // public anomaly_type: AnomalyType,
        // public anomaly_distance: number,
        // public anomaly_flow: number,
        // public anomaly_low_value: number,
        // public anomaly_high_value: number,
        // public place_id: number,
        // public place_label: String,
        // public place_latitude: number,
        // public place_longitude: number,
        // public is_active: boolean
    ) { }

}



@Injectable({
    providedIn: 'root'
})
export class AlertAdapter implements Adapter<Alert> {

    private _user: User;

    constructor(
        cacheService: CacheService,
        userSvc: UserService,
        private _utilsSvc: UtilsService,
        private _alertDefinitionAdapter: AlertDefinitionAdapter,
        private _meterAdapter: MeterAdapter,
        private _placeAdapter: PlaceAdapter) {
        this._user = cacheService.inMemoryData.get('user') as User;
        userSvc.getUserData()
            .subscribe((u) => this._user = u);
    }

    adapt(item: any): Alert {
        return new Alert(
            () => this._user,
            this._utilsSvc,
            new Anomaly(
                item.anomaly.id,
                item.anomaly.type,
                item.anomaly.is_active,
                item.anomaly.begin_date ? DateTime.fromISO(item.anomaly.begin_date) : null,
                item.anomaly.end_date ? DateTime.fromISO(item.anomaly.end_date) : null,
                item.anomaly.notified_on ? DateTime.fromISO(item.anomaly.notified_on) : null,
                item.anomaly.end_notified_on ? DateTime.fromISO(item.anomaly.end_notified_on) : null,
                item.anomaly.distance,
                item.anomaly.flow,
                item.anomaly.low_value,
                item.anomaly.high_value
            ),
            item.definition ? this._alertDefinitionAdapter.adapt(item.definition) : null,
            item.device ? new AlertDevice(item.device.uuid, item.device.type, item.device.label, item.device.serial) : null,
            item.place ? this._placeAdapter.adapt(item.place) : null
        );
    }
}