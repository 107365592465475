<div class="leaflet-map" leaflet  id="bubble-map"
     [leafletOptions]="mapOptions"
     [leafletCenter]="selectedBubble"
     [leafletFitBounds]="mapFitToBounds"
     (leafletMapReady)="onMapReady($event)"
     [leafletFitBoundsOptions]="mapFitToBoundsOptions"
     [leafletMarkerCluster]="markerClusterData"
     [leafletMarkerClusterOptions]="markerClusterOptions"
     (leafletMarkerClusterReady)="onMarkerClusterReady($event)"
     (leafletMapMoveEnd)="onMoveEnd($event)"
     (leafletMapZoomEnd)="onZoomEnd($event)"
     >
<svg></svg></div>