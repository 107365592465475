import { Injectable } from '@angular/core';
import { DateTime, Settings } from 'luxon';
import { Adapter } from 'src/app/core/adapter';
import { Model } from 'src/app/core/models/model';
import { GraphElementDto } from 'src/app/shared/components/graph/dto/graph-element-dto';
import { ILineElement } from 'src/app/shared/components/line-chart/interfaces/iline-element';
import { GraphGrouping } from 'src/app/shared/types/hydrao-types';



export class GraphElement implements Model, GraphElementDto {
    constructor(
        public date: DateTime,
        public volume: number
    ) { }
   

    public get xLabel(): String {
        return this.date.toISO();
    }
    public get barValue(): number {
        return this.volume;
    };
    public bar2Value: number = null;
    public lineValue: number = null;
    public line2Value: number = null;
    public barStyle: string = null;
  


}


export class BarGraphElement implements Model, GraphElementDto {
    constructor(
        public date: DateTime,
        public volume_meter: number,
        public volume_sh: number
    ) { }
   

    public get xLabel(): String {
        return this.date.toISO();
    }
    public get barValue(): number {
        return this.volume_sh;
    };
    public get bar2Value(): number {
        return this.volume_meter;
    };
    public lineValue: number = null;
    public line2Value: number = null;
    public barStyle: string = null;
  


}





export class ShGraphElement implements Model {
    constructor(
        public date: DateTime,
        public nb_showers: number,
        public total_vol: number,
        public total_duration: number,
    ) { }
    // public get xLabel(): String {
    //     return this.date.toISOString();
    // }
    // public get barValue(): number {
    //     return this.total_vol;
    // };
    // public lineValue: number = null;
}



export class MeterGraphElement implements Model {
    constructor(
        public date: DateTime,
        public average_flow: number,
        public total_duration: number,
        public total_vol: number,
    ) { }
    // public get xLabel(): String {
    //     return this.date.toISOString();
    // }
    // public get barValue(): number {
    //     return this.total_vol;
    // };
    // public lineValue: number = null;
}

export class ShStatsDetails implements Model {
    constructor(
        public grouping: string,
        public data: ShGraphElement[]
    ) { }

    public get isDayGrouping() {
        return this.grouping == 'day';
    }

    public get isMonthGrouping() {
        return this.grouping == 'month';
    }

    public get isHourGrouping() {
        return this.grouping == 'hour';
    }
}

export enum MetricCategory {
    showerhead,
    meter
}

export enum MetricType {
    volume,
    temperature,
    duration,
    other
}

export enum ShowerHeadMetricId {
    volume = 'volume',
    nb_showers = 'nb_showers',
    avg_shower = 'avg_shower',
    avg_duration = 'avg_duration',
    nb_sh = 'nb_sh',
    total_shower_per_day = 'total_shower_per_day'
}

export enum MeterMetricId {
    meter_volume = 'meter_volume'
}


export class MetricElement {

    public type: MetricType;
    public category: MetricCategory;
    public label: String;
    public svgIcon: String = null;
    public matIcon: String = null;
    public id: ShowerHeadMetricId | MeterMetricId;

    constructor(public value: number, id: ShowerHeadMetricId | MeterMetricId) {
        this.svgIcon = null;
        this.matIcon = null;
        this.id = id;
        switch (id) {
            case ShowerHeadMetricId.volume:
                this.type = MetricType.volume;
                this.category = MetricCategory.showerhead;
                this.label = $localize`total shower volume`;
                this.svgIcon = 'drop';
                break;
            case ShowerHeadMetricId.nb_showers:
                this.type = MetricType.other;
                this.category = MetricCategory.showerhead;
                this.label = $localize`total number of showers`;
                this.svgIcon = 'shower';
                break;
            case ShowerHeadMetricId.avg_shower:
                this.type = MetricType.volume;
                this.category = MetricCategory.showerhead;
                this.label = $localize`average shower volume`;
                this.svgIcon = 'water_usage';
                break;
            case ShowerHeadMetricId.avg_duration:
                this.type = MetricType.duration;
                this.category = MetricCategory.showerhead;
                this.label = $localize`average shower duration`;
                this.svgIcon = 'chrono';
                break;
            case ShowerHeadMetricId.total_shower_per_day:
                this.type = MetricType.other;
                this.category = MetricCategory.showerhead;
                this.label = $localize`total shower per day`;
                this.matIcon = 'today';
                break;
            // case ShowerHeadMetricId.nb_places:
            //     this.type = MetricType.other;
            //     this.category = MetricCategory.place;
            //     this.label = $localize`number of places`;
            //     this.matIcon = 'location_city';
            //     break;
            case ShowerHeadMetricId.nb_sh:
                this.type = MetricType.other;
                this.category = MetricCategory.showerhead;
                this.label = $localize`number of showerheads`;
                this.svgIcon = 'shower_head';
                break;
            case MeterMetricId.meter_volume:
                this.type = MetricType.volume;
                this.category = MetricCategory.meter;
                this.label = $localize`total meter volume`;
                this.svgIcon = 'meter';
                break;
        }

    }
}


export class GlobalStats implements Model {

    public metrics: MetricElement[];

    private metricMap: Map<ShowerHeadMetricId, MetricElement>;

    public details: GraphElement[];

    public lineChart: ILineElement[];

    public barChart: BarGraphElement[];


    public buildMetricList(metricIds: string[], canViewMeter = true, canViewSh = true, canViewPlace = true) {
        this.metrics = new Array<MetricElement>();


        for (let metricId of metricIds) {
            let metric: MetricElement = this.metricMap[metricId];
            if (metric
                && (canViewMeter || metric.category !== MetricCategory.meter)
                && (canViewSh || metric.category !== MetricCategory.showerhead)) {
                this.metrics.push(metric);
            }
        }

    }

    public buildGraph(from: DateTime, to: DateTime, grouping: GraphGrouping) {

        //we assume that graph elements are already ordered
        if (!this.sh_details && !this.meter_details) {
            return; //nothing to do
        }

        if (!from) {
            if (this.sh_details && this.sh_details.length > 0) {
                from = this.sh_details[0].date;
            }
            if (this.meter_details && this.meter_details.length > 0) {
                if ((from && this.meter_details[0].date < from) || !from) {
                    from = this.meter_details[0].date;
                }
            }
        }


        if (!to) {
            if (this.sh_details && this.sh_details.length > 0) {
                to = this.sh_details[this.sh_details.length - 1].date;
            }
            if (this.meter_details && this.meter_details.length > 0) {
                if ((to && this.meter_details[this.meter_details.length - 1].date > to) || !to) {
                    to = this.meter_details[this.meter_details.length - 1].date;
                }
            }
        }
        let addQty = null;
        let compareQty = null;

        switch (grouping) {
            case GraphGrouping.months:
                addQty = 'months';
                compareQty = 'month';
                break;
            case GraphGrouping.days:
                addQty = 'days';
                compareQty = 'day';
                break;
            case GraphGrouping.hours:
                addQty = 'hours';
                compareQty = 'hour';
                break;
        }
        if (addQty && compareQty) {
            this.details = [];
            for (let curElem: DateTime = from; curElem <= to; curElem = curElem.plus({ [addQty]: 1 })) {
                let volShower = null, volMeter = null;
                if (this.sh_details) {
                    let sElem = this.sh_details.find(s => s.date.hasSame(curElem, compareQty));
                    if (sElem) volShower = sElem.total_vol;
                }
                if (this.meter_details) {
                    let mElem = this.meter_details.find(s => s.date.hasSame(curElem, compareQty));
                    if (mElem) volMeter = mElem.total_vol;
                }
                if (this.sh_details) {
                    this.details.push(new GraphElement(curElem, volShower));
                } else if (this.meter_details) {
                    this.details.push(new GraphElement(curElem, volMeter));
                }

            }
        }



    }


    constructor(
        public volume: number,
        public meter_volume: number,
        public nb_showers: number,
        public nb_places: number,
        public nb_sh: number,
        public nb_sh_active_today: number,
        public nb_meter:number,
        public nb_meter_active_today: number,
        public avg_shower: number,
        public avg_duration: number,
        public total_shower_per_day: number,
        public sh_details: ShGraphElement[],
        public meter_details: MeterGraphElement[],
        public last_meter_update: Date,
        public last_sh_update: Date
    ) {
        this.metricMap = new Map<ShowerHeadMetricId, MetricElement>();
        if (volume !== null) {
            this.metricMap[ShowerHeadMetricId.volume] = new MetricElement(volume, ShowerHeadMetricId.volume);
        }

        if (nb_showers !== null) {
            this.metricMap[ShowerHeadMetricId.nb_showers] = new MetricElement(nb_showers, ShowerHeadMetricId.nb_showers);
        }
        // if (nb_places !== null) {
        //     this.metricMap[ShowerHeadMetricId.nb_places] = new MetricElement(nb_places, ShowerHeadMetricId.nb_places);
        // }
        if (nb_sh !== null) {
            this.metricMap[ShowerHeadMetricId.nb_sh] = new MetricElement(nb_sh, ShowerHeadMetricId.nb_sh);
        }
        if (avg_shower !== null) {
            this.metricMap[ShowerHeadMetricId.avg_shower] = new MetricElement(avg_shower, ShowerHeadMetricId.avg_shower);
        }
        if (avg_duration !== null) {
            this.metricMap[ShowerHeadMetricId.avg_duration] = new MetricElement(avg_duration, ShowerHeadMetricId.avg_duration);
        }
        if (total_shower_per_day !== null) {
            this.metricMap[ShowerHeadMetricId.total_shower_per_day] = new MetricElement(total_shower_per_day, ShowerHeadMetricId.total_shower_per_day);
        }
        if (meter_volume !== null) {
            this.metricMap[MeterMetricId.meter_volume] = new MetricElement(meter_volume, MeterMetricId.meter_volume);
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class ShGraphElementAdapter implements Adapter<ShGraphElement> {
    constructor() { }
    adapt(item: any): ShGraphElement {
        return new ShGraphElement(
            DateTime.fromISO(item.date, { zone: Settings.defaultZone }),
            item.nb_showers,
            item.total_vol ?  item.total_vol : item.volume,
            item.total_duration ? item.total_duration : item.duration        
        );
    }
}

@Injectable({
    providedIn: 'root'
})
export class MeterGraphElementAdapter implements Adapter<MeterGraphElement> {
    constructor() { }
    adapt(item: any): MeterGraphElement {
        return new MeterGraphElement(
            DateTime.fromISO(item.date, { zone: Settings.defaultZone }),
            item.average_flow,
            item.total_duration ? item.total_duration : item.duration,
            item.total_vol? item.total_vol : item.volume
        );
    }
}



@Injectable({
    providedIn: 'root'
})
export class GlobalStatsAdapter implements Adapter<GlobalStats> {

    constructor(private shGraphElementAdapter: ShGraphElementAdapter, private meterGraphElementAdapter: MeterGraphElementAdapter) { }
    adapt(item: any): GlobalStats {
        // * 1 is to convert null values to 0
        return new GlobalStats(
            item.hasOwnProperty('volume') ? item.volume * 1 : null,
            item.hasOwnProperty('meter_volume') ? item.meter_volume * 1 : null,
            item.hasOwnProperty('nb_showers') ? item.nb_showers * 1 : null,
            item.hasOwnProperty('nb_places') ? item.nb_places * 1 : null,
            item.hasOwnProperty('nb_sh') ? item.nb_sh * 1 : null,
            item.hasOwnProperty('nb_sh_active_today') ? item.nb_sh_active_today * 1 : null,
            item.hasOwnProperty('nb_meter') ? item.nb_meter * 1 : null,
            item.hasOwnProperty('nb_meter_active_today') ? item.nb_meter_active_today * 1 : null,
            item.hasOwnProperty('avg_shower') ? item.avg_shower * 1 : null,
            item.hasOwnProperty('avg_duration') ? item.avg_duration * 1 : null,
            item.hasOwnProperty('total_shower_per_day') ? item.total_shower_per_day * 1 : null,
            item.hasOwnProperty('sh_details') && Array.isArray(item.sh_details) ? item.sh_details.map(i => this.shGraphElementAdapter.adapt(i)) : null,
            item.hasOwnProperty('meter_details') && Array.isArray(item.meter_details) ? item.meter_details.map(i => this.meterGraphElementAdapter.adapt(i)) : null,
            item.last_meter_update ? new Date(item.last_meter_update): null,
            item.last_sh_update ? new Date(item.last_sh_update) : null
        );
    }
}



