import { Injectable } from '@angular/core';
import { deleteDB, IDBPDatabase, openDB } from 'idb';
import { NGXLogger } from 'ngx-logger';

const LOG_TAG = '[DB]';

@Injectable({
  providedIn: 'root'
})
export class DbService {
  private _db: IDBPDatabase = null;
  private static _LOG_STORE_NAME = 'log';
  private static _CACHE_STORE_NAME = 'cache';
  private static _DB_VERSION = 4;

  private _openPromise: Promise<void> = null;
  constructor() {
    this._openDb();
  }

  private _openDb(): Promise<void> {
    if (!this._db) {
      let wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
      if (this._openPromise) return this._openPromise;
      console.log(LOG_TAG, 'open db');

      this._openPromise = Promise.race([

        openDB('hydrao-db', DbService._DB_VERSION, {
          upgrade(db) {
            db.createObjectStore(DbService._LOG_STORE_NAME);
            db.createObjectStore(DbService._CACHE_STORE_NAME);

            console.log(LOG_TAG, 'store created');

          }
        }).then((db) => {
          console.log(LOG_TAG, 'db opened');
          return db;
        }),
        wait(8000)
          .then(() => {
            if (!this._db) {
              console.warn(LOG_TAG, 'db open stucked, remove db and try again');
              return deleteDB('hydrao-db').then(() => {
                this._openPromise = null;
                return this._openDb();
              });
            }
          })
          .then(() => null)
      ]).then((db) => {
        this._db = db;
        console.log('db opened');
      });
      return this._openPromise;
    }
    return Promise.resolve();
  }


  public async writeLog(message: string, timestamp: string) {
    await this._openDb();//make sure db is opened. 
    await this._db.put(DbService._LOG_STORE_NAME, message, timestamp);

  }

  public async getAllLog(): Promise<string[]> {
    await this._openDb();
    let res: string[] = await this._db.getAll(DbService._LOG_STORE_NAME);
    return res;
  }

  public async clearAllLog(): Promise<void> {
    await this._openDb();
    await this._db.clear(DbService._LOG_STORE_NAME);
  }





  public async writeCache(value: object, key: string) {
    await this._openDb();//make sure db is opened. 
    await this._db.put(DbService._CACHE_STORE_NAME, value, key);

  }

  public async getCache(key: string): Promise<object> {
    await this._openDb();
    return await this._db.get(DbService._CACHE_STORE_NAME, key);
  }


  /**
    * Clear specific cache data
    * @param key The key
    */
  public async clearCacheEntry(key: string): Promise<void> {
    await this._openDb();
    await this._db.delete(DbService._CACHE_STORE_NAME, key)
  }



  /**
   * empty all caches
   */
  public async clearAllCache(): Promise<void> {
    await this._openDb();
    await this._db.clear(DbService._CACHE_STORE_NAME);
  }
}
