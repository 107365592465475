<div class="login-page">
    <hydrao-admin-bar></hydrao-admin-bar>
    <div class="logo-container">
        <div class="hydrao-logo">
            <img src="assets/logo-smart.svg" />
        </div>
    </div>
    <mat-card appearance="outlined" class="mat-elevation-z0">
        <p i18n>Please select your timezone</p>
        <hydrao-timezone-select (timezoneChange)="tzChange($event)"></hydrao-timezone-select>
        <p>
            <button mat-flat-button color="primary" (click)="validateTimezone()" [disabled]="!okEnabled" i18n>
                ok
            </button>
        </p>

    </mat-card>

</div>