import { Injectable, isDevMode } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { first, map, Observable, of, switchMap, tap } from 'rxjs';
import { HydraoLogMonitor } from 'src/app/core/log-monitor';
import { AuthService } from 'src/app/core/services/auth.service';
import { CacheService } from 'src/app/core/services/core/cache.service';
import { UserService } from 'src/app/core/services/user.service';
import { TourService } from 'src/app/shared/services/tour.service';

const LOG_TAG = '[NAV]';


@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {


    private get _isAdminModeEnabled(): boolean {
        return this._cookieService.check('admin_on_behalf');
    }


    private _logFlushed = false;



    constructor(
        private _router: Router,
        private _authService: AuthService,
        private _cacheService: CacheService,
        private _userService: UserService,
        private _logger: NGXLogger,
        private _tourService: TourService,
        private _cookieService: CookieService,
        private _logMonitor: HydraoLogMonitor) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        if (this._authService.needAuth) {
            // not logged in so redirect to login page with the return url
            console.log('cannot activate');
            this._cacheService.clearAll().pipe(first()).subscribe(() => this._router.navigate(['/login']));

            return of(false);
        } else {
            return this._userService.getUserData().pipe(
                first(),
                map((u) => {
                    if (!this._logFlushed) {
                        this._logMonitor.startLogFlushing();
                        this._logFlushed = true;
                    }

                    this._logger.info(LOG_TAG, 'navigate to ' + state.url);

                    if (isDevMode()) {
                        this._logger.warn(LOG_TAG, 'development mode, not redirecting for language');
                    } else {
                        if ((window.location.pathname.startsWith('/fr') && u.my_locale !== 'fr') || (!window.location.pathname.startsWith('/fr') && u.my_locale == 'fr')) {
                            this._logger.debug(LOG_TAG, 'wrong locale, redirecting');
                            window.location.href = `/${u.my_locale}${state.url}`;
                        }
                    }

                    if (state.url == '/dashboard') {
                        if (!this._isAdminModeEnabled) {
                            if (!u.canViewMeters() && !u.canViewSh()) return this._router.parseUrl('/onboarding');
                            if (!u.timezone) return this._router.parseUrl('/onboarding/timezone');
                        }
                        if (u.canViewMeters() && !u.canViewSh() && !u.canViewPlaces()) return this._router.parseUrl('meters');
                        if (!u.canViewMeters() && u.canViewSh() && !u.canViewPlaces()) return this._router.parseUrl('shower-heads');
                        this._tourService.starGeneralTour().subscribe();
                    } else if (state.url.startsWith('/meters')) {
                        this._logger.debug(LOG_TAG, 'navigate to meter');
                        this._tourService.startMeterTour().subscribe();
                    } else if (state.url.startsWith('/places')) {
                        this._tourService.startPlaceTour().subscribe();
                    } else if (state.url.startsWith('/alerts')) {
                        this._tourService.startAlertTour().subscribe();
                    } else if (state.url.startsWith('/shower-heads')) {
                        this._tourService.startShTour().subscribe();
                    }
                    return true;
                }));

        }


    }
}