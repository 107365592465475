<!-- <mat-form-field appearance="fill">
    <mat-label>Select</mat-label>
    <mat-select>
        <mat-option value="one">First option</mat-option>
        <mat-option value="two">Second option</mat-option>
    </mat-select>
</mat-form-field> -->



<mat-form-field appearance="outline">
    <mat-label i18n>Timezone</mat-label>
    <mat-select required [(ngModel)]="timezone" (selectionChange)="onChange($event)">
        <mat-option *ngFor="let tz  of timezones" [value]="tz">{{tz}}</mat-option>
    </mat-select>
    <mat-hint i18n>Current time : {{date}}</mat-hint>
</mat-form-field>

