import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'hydrao-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent  {

  constructor(private _authService: AuthService) { }
  public logoutDisabled = false;

  public logout() {
    this.logoutDisabled = true;

    this._authService.logout();
  }

}
