import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { User } from 'src/app/core/models/user.model';
import { CacheService } from 'src/app/core/services/core/cache.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'hydrao-timezone-first-select',
  templateUrl: './timezone-first-select.component.html',
  styleUrls: ['./timezone-first-select.component.scss']
})
export class TimezoneFirstSelectComponent {

  public get okEnabled(): boolean {
    return this._currentTimezone !== null && this._user !== null && !this._loading;
  }

  private _currentTimezone = null;
  private _user: User = null;
  private _loading = false;

  constructor(
    private _logger: NGXLogger,
    private _userSvc: UserService,
    private _router: Router,
    cacheSvc: CacheService) {


    this._user = cacheSvc.inMemoryData.get('user') as User;
    if (this._user) this._checkTz();
    this._userSvc.getUserData().subscribe((u) => {
      this._user = u;
      this._checkTz();
    });

  }

  private _checkTz() {
    if (this._user && this._user.timezone) {
      this._logger.info(`User timezone already set to ${this._user.timezone}, nothing to do here.`);
      this._router.navigate(['/'], { replaceUrl: true });
    }

  }

  public tzChange(timezone: string) {
    this._currentTimezone = timezone;
  }

  public validateTimezone() {
    this._logger.info(`set user timezone to ${this._currentTimezone}`);
    this._loading = true;
    this._user.timezone = this._currentTimezone;
    this._userSvc.saveUserData(this._user).subscribe((user) => {
      this._user = user;
      this._loading = false;
      this._router.navigate(['/'], { replaceUrl: true });

    });
  }
}
