import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { AccountService } from 'src/app/core/services/accounts/account.service';
import { lastValueFrom } from 'rxjs';




@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent implements OnInit {


  public registerForm: UntypedFormGroup;
  public errorCode: string = null;
  private email: string = null;

  constructor(
    private logger: NGXLogger,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    let emailBase64 = this.route.snapshot.paramMap.get('emailBase64');
    if (emailBase64) {
      try {
        this.email = atob(emailBase64);
      }
      catch (e) {
        this.logger.warn('cannot decode email ');
        this.email = null;
      }
    }
    this.registerForm = new UntypedFormGroup({
      email: new UntypedFormControl({ value: this.email, disabled: this.email != null }, [Validators.required, Validators.email]),
      pass: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      passConf: new UntypedFormControl('', [Validators.required])
    }, MustMatch('pass', 'passConf'));


  }


  public hasError(controlName: string, errorName: string) {
    return this.registerForm.controls[controlName].hasError(errorName);
  }

  public async register(loginFormValue) {
    if (this.registerForm.valid) {
      this.errorCode = null;
      this.logger.debug(`login with ${loginFormValue.email}`);
      let email = this.email != null ? this.email : loginFormValue.email;
      try {
        this.accountService.registerUser(email, loginFormValue.pass).subscribe(() => {
          this.logger.info('register successfull');
          localStorage.setItem('login', email);
          this.router.navigate(['/verify-account']);
        });

      } catch (err) {
        this.errorCode = err ? err.error ? err.error.error : null : null;
      };
    }
  }

  public goBack() {
    history.back();
  }




}
function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
    return null;
  };
}