<div class="form-wrapper">
    <mat-card appearance="outlined">
        <div class="hydrao-card-title" ><span i18n>Recover password</span> (2/2)</div>

        <div *ngIf="passChanged">
            <p i18n>Password successfully changed, please login</p>
            <div class="buttons">
                <button  mat-flat-button color="primary" type="button" i18n (click)="goToLogin()">Go to login page</button>
            </div>
        </div>
        <form [formGroup]="forgotPassForm" autocomplete="off" novalidate (ngSubmit)="submit(forgotPassForm.value)"
            fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="!passChanged">
            <p i18n>A code has been sent to {{email}}</p>
            <mat-form-field>
                <input required matInput i18n-placeholder placeholder="email" type="email" formControlName="email"
                    id="email" autofocus>
                <mat-error *ngIf="hasError('email', 'required')" i18n>Email is required</mat-error>
                <mat-error *ngIf="hasError('email', 'email')" i18n>Please enter a valid email</mat-error>
                <mat-error *ngIf="hasError('email', 'userNotFound')" i18n>User not found, please verify you email</mat-error>
            </mat-form-field>
            <mat-form-field>
                <input required matInput i18n-placeholder placeholder="code sent by email" type="text" formControlName="code"
                    id="code">
                <mat-error *ngIf="hasError('code', 'required')" i18n>Confirmation code is required</mat-error>
                <mat-error *ngIf="hasError('code', 'wrongCode')" i18n>Wrong code, please verify</mat-error>
            </mat-form-field>
            <mat-form-field>
                <input required matInput i18n-placeholder placeholder="password" type="password" formControlName="pass"
                    id="pass">
                <mat-error *ngIf="hasError('pass', 'required')" i18n>Password is required</mat-error>
                <mat-error *ngIf="hasError('pass', 'minlength')" i18n>Password length is at least 8 characters
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <input required matInput i18n-placeholder placeholder="password confirmation" type="password"
                    formControlName="passConf" id="passConf">
                <mat-error *ngIf="hasError('passConf', 'required')" i18n>Password confirmation is required</mat-error>
                <mat-error *ngIf="hasError('passConf', 'mustMatch')" i18n>Password confirmation does not match
                </mat-error>
            </mat-form-field>
          
            <mat-progress-spinner diameter="20" strokeWidth="1" *ngIf="loading" mode="indeterminate">
            </mat-progress-spinner>
            <div class="buttons" *ngIf="!loading">
                <button mat-button color="primary" type="button" i18n (click)="goBack()">Go Back</button>
                <button mat-flat-button color="primary" [disabled]="!forgotPassForm.valid" i18n>recover password</button>
            </div>
           
        </form>
    </mat-card>
</div>