<div class="offline-page">
    <div class="logo-container">
        <div class="hydrao-logo">
            <img src="assets/logo-smart.svg" />
        </div>
    </div>
    <mat-card appearance="outlined" class="mat-elevation-z0">
        <span i18n class="header">HYDRAO is currently down for maintenance</span>
        <span i18n class="text">We will be back soon. Thanks for your patience.</span>
    </mat-card>



    <div class="version"> v{{version}}&nbsp;-&nbsp;{{hash}}</div>
</div>