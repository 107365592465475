import { Injectable } from '@angular/core';
import { Adapter } from 'src/app/core/adapter';
import { Model } from 'src/app/core/models/model';


export class HydraoResponse implements Model {
    constructor(
        public status: string,
        public message: string
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class HydraoResponseAdapter implements Adapter<HydraoResponse> {

    adapt(item: any): HydraoResponse {
        return new HydraoResponse(
            item.status,
            item.message
        );
    }
}