import { Injectable } from '@angular/core';
import { Adapter } from 'src/app/core/adapter';
import { Model } from 'src/app/core/models/model';

export enum ThresholdColor {
    black = 'black',
    light_green = 'light_green',
    green = 'green',
    light_blue = 'light_blue',
    blue = 'blue',
    purple = 'purple',
    red = 'red',
    orange = 'orange',
    yellow = 'yellow'
}


export class Threshold implements Model {
    constructor(
        public liter: number,
        public color: ThresholdColor
    ) { }

    public get displayColor() {
        switch (this.color) {
            case ThresholdColor.yellow:
                return '#E3A003';
            case ThresholdColor.orange:
                return '#F36F16';
            case ThresholdColor.red:
                return '#DC2926';
            case ThresholdColor.purple:
                return '#BC59AF';
            case ThresholdColor.blue:
                return '#008DCC';
            case ThresholdColor.light_blue:
                return '#49ABBF';
            case ThresholdColor.green:
                return '#52A684';
            case ThresholdColor.light_green:
                return '#83B663';
            case ThresholdColor.black:
            default:
                return '#000000';
        }
    }

    public get rgbColor() {
        switch (this.color) {
            case ThresholdColor.black:
                return '000000';
            case ThresholdColor.light_green:
                return '67FF11';
            case ThresholdColor.green:
                return '00FF00';
            case ThresholdColor.light_blue:
                return '0AD2F0';
            case ThresholdColor.blue:
                return '0000FF';
            case ThresholdColor.purple:
                return 'FF00B4';
            case ThresholdColor.red:
                return 'FF0000';
            case ThresholdColor.orange:
                return 'FF5000';
            case ThresholdColor.yellow:
                return 'FAB900';
        }
    }

    public toJsonObject() {
        return {
            liter: this.liter,
            color: this.rgbColor
        }
    }




    public equals(otherThreshold: Threshold) {
        return otherThreshold && this.liter == otherThreshold.liter && this.color == otherThreshold.color;
    }

    public static get DEFAULT_THRESHOLDS(): Threshold[] {
        return [
            new Threshold(10, ThresholdColor.green),
            new Threshold(20, ThresholdColor.blue),
            new Threshold(30, ThresholdColor.purple),
            new Threshold(40, ThresholdColor.red),
        ];
    }


    public static get AVAILABLE_COLORS(): Threshold[] {
        return Object.values(ThresholdColor).map((color: ThresholdColor) => new Threshold(0, color));
    };

    public static rgbToColor(rgb: string): ThresholdColor {
        let color = rgb.toUpperCase();
        if (color.length > 6) {
            //remove trailing FF
            color = color.slice(-6);
        }
        switch (color) {
            case '000000':
                return ThresholdColor.black;
            case '67FF11':
                return ThresholdColor.light_green;
            case '00FF00':
                return ThresholdColor.green;
            case '0AD2F0':
            case '4CDDF1':
                return ThresholdColor.light_blue;
            case '0000FF':
                return ThresholdColor.blue;
            case 'FF00B4':
            case 'FF00FF':
                return ThresholdColor.purple;
            case 'FF0000':
                return ThresholdColor.red;
            case 'FF5000':
            case 'FF7100':
                return ThresholdColor.orange;
            case 'FAB900':
            case 'FFDE1A':
                return ThresholdColor.yellow;
            default:
                return ThresholdColor.black;
        }
    }

}



@Injectable({
    providedIn: 'root'
})
export class ThresholdAdapter implements Adapter<Threshold> {

    adapt(item: any): Threshold {
        return new Threshold(
            item.liter,
            Threshold.rgbToColor(item.color),
        );
    }
}