import { Injectable } from '@angular/core';
import { Adapter } from 'src/app/core/adapter';
import { Model } from 'src/app/core/models/model';
import { PlaceStats } from 'src/app/core/models/placestats.model';
import { IBubbleElement, LatLon } from 'src/app/shared/components/bubble-map/bubble-map.component';


export class Place implements Model, IBubbleElement {

    public get size(): number {
        if (this.stats) {
            return this.stats.global.volume;
        } else {
            return 0;
        }
    }


    public coordinates: LatLon;

    public stats: PlaceStats;

    public static emptyPlace(label: string = '', parentId = null): Place {
        return new Place(0, label, label, '', null, null, null, parentId, [], 0);
    }

    constructor(
        public id: number,
        public label: string,
        public full_path: string,
        public description: string,
        public latitude: number,
        public longitude: number,
        public hierarchy_level: number,
        public parent_id: number,
        public children: Array<Place>,
        public anomalies: number
    ) {
        this.coordinates = new LatLon(latitude, longitude);
    }

    public static fromPlace(place: Place): Place {
        let pl = new Place(
            place.id,
            place.label,
            place.full_path,
            place.description,
            place.latitude,
            place.longitude,
            place.hierarchy_level,
            place.parent_id,
            place.children ? place.children.map(c => Place.fromPlace(c)) : null,
            place.anomalies
        );
        pl.stats = place.stats;
        return pl;
    }

    public clone() {
        return new Place(
            this.id,
            this.label,
            this.full_path,
            this.description,
            this.latitude,
            this.longitude,
            this.hierarchy_level,
            this.parent_id,
            this.children,
            this.anomalies
        );
    }
}



@Injectable({
    providedIn: 'root'
})
export class PlaceAdapter implements Adapter<Place> {
    adapt(item: any): Place {
        return new Place(
            item.id,
            item.label,
            item.label,
            item.description,
            item.latitude,
            item.longitude,
            item.hierarchy_level,
            item.parent_id,
            Array.isArray(item.children) ? item.children.map(i => this.adapt(i)) : null,
            item.anomalies
        );
    }
}