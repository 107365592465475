import { Injectable } from '@angular/core';
import { Settings } from 'luxon';
import { CookieService } from 'ngx-cookie-service';
import { lastValueFrom, Observable } from 'rxjs';
import { ignoreElements, map } from 'rxjs/operators';
import { User } from 'src/app/core/models/user.model';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';


export class ValueWithUnit {
  constructor(
    public value: number,
    public unit: string) { }

  public toString() {
    return `${this.value ? this.value : 0} ${this.unit}`;
  }

}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private userService: UserService) { }

  public gallonsToLiters(gallons: number) {
    return Math.round(3.78541 * gallons);
  }

  public literToGallons(liters: number) {
    return Math.round(liters * 0.264172 * 10) / 10;
  }


  public literToCCF(liters: number) {
    return Math.round(liters * 0.000353147 * 10) / 10;
  }

  public CCFToLiter(liters: number) {
    return Math.round(liters * 2831.7);
  }

  public celciusToFahrenheit(degrees: number) {
    return Math.round((degrees * 1.8) + 32);
  }

  public get locale() {
    let locale = 'en';

    if (environment.production && window.location.pathname.startsWith('/fr')) {
      locale = 'fr';
    }
    return locale;
  }

  public get StatsValue(): number {
    let statsValue = Number(localStorage.getItem('stats'));
    //default : last month
    return statsValue && !isNaN(statsValue) ? statsValue : 43800;
  }

  public set StatsValue(value: number) {
    localStorage.setItem('stats', value.toString());
  }

  public toBase64Url(inputString) {
    return btoa(inputString).replace(/=/g, "")
      .replace(/\+/g, "-")
      .replace(/\//g, "_");
  }

  public getVolumeFormattedWithUser(user: User, liters: number): ValueWithUnit {
    if (user && user.useLiters()) {
      if (liters > 999) {
        return new ValueWithUnit(
          Number((liters / 1000).toFixed(1)),
          'm³'
        )
      } else if (liters >= 100) {
        return new ValueWithUnit(
          Math.round(liters),
          'L'
        )
      } else {
        return new ValueWithUnit(
          liters,
          'L'
        )
      }
    } else {
      if (this.literToGallons(liters) > 999) {
        return new ValueWithUnit(
          this.literToCCF(liters),
          'CCF'
        );
      } else {
        return new ValueWithUnit(
          this.literToGallons(liters),
          'Gal'
        );
      }

    }
  }

  public getVolumeFormatted(liters: number): Observable<ValueWithUnit> {
    return this.userService.getUserData()
      .pipe(map((user) => this.getVolumeFormattedWithUser(user, liters)));
  }

  public getTemperatureFormattedWithUser(user: User, temperature: number): ValueWithUnit {
    if (user && user.useLiters()) {
      return new ValueWithUnit(temperature, '°C');
    } else {
      return new ValueWithUnit(this.celciusToFahrenheit(temperature), '°F');
    }
  }
  public getTemperatureFormatted(temperature: number): Observable<ValueWithUnit> {
    return this.userService.getUserData().pipe(map((user) => this.getTemperatureFormattedWithUser(user, temperature)))
  }

  public getFlowFormatted(flow: number): Observable<ValueWithUnit> {
    return this.userService.getUserData().pipe(map((user) => this.getFlowFormattedWithUser(user, flow)));
  }

  public getFlowFormattedWithUser(user: User, flow: number): ValueWithUnit {
    if (user && user.useLiters()) {
      return new ValueWithUnit(flow, 'L/min');
    } else {
      return new ValueWithUnit(this.literToGallons(flow), 'Gal/min');
    }
  }

  public getTimezone(): string {
    return Settings.defaultZone ? Settings.defaultZone.name : Intl.DateTimeFormat().resolvedOptions().timeZone;
  }


  public getD3TimeLocale(): d3.TimeLocaleDefinition {
    //returned from https://unpkg.com/d3-time-format@2.3.0/locale/en-US.json
    if (this.locale == 'fr') {
      return {
        "dateTime": "%A %e %B %Y à %X",
        "date": "%d/%m/%Y",
        "time": "%H:%M:%S",
        "periods": ["AM", "PM"],
        "days": ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
        "shortDays": ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
        "months": ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
        "shortMonths": ["janv.", "févr.", "mars", "avr.", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."]
      };

    } else {
      return {
        "dateTime": "%x, %X",
        "date": "%-m/%-d/%Y",
        "time": "%-I:%M:%S %p",
        "periods": ["AM", "PM"],
        "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      };

    }
  }

}
