//libs
import { BrowserModule } from '@angular/platform-browser';

import { ErrorHandler, NgModule } from '@angular/core';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask'
import { InlineSVGModule } from 'ng-inline-svg-2';



import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { CookieService } from 'ngx-cookie-service';


// angular modules
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';



//custom modules
import { AppRoutingModule } from 'src/app/app-routing.module';
import { LoginModule } from 'src/app/login/login.module';
import { TokenInterceptor } from 'src/app/core/auth.interceptor';
import { RegisterModule } from 'src/app/register/register.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { OnboardingModule } from 'src/app/onboarding/onboarding.module';
import { JoyrideModule } from 'ngx-joyride';
import { MatNativeDateModule } from '@angular/material/core';




//components
import { AppComponent } from 'src/app/app.component';
import { LayoutComponent } from 'src/app/layout/layout.component';
import { GlobalErrorHandler } from 'src/app/core/error-handler';

//export const maskOptions: Partial<IConfig> | (() => Partial<IConfig>);

export const maskOptions: Partial<IConfig> | (() => Partial<IConfig>) = {};


registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
  ],
  imports: [
    RouterModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      enableSourceMaps: false,
      disableFileDetails: true, //TRACE|DEBUG|INFO|LOG|WARN|ERROR|FATAL|OFF
      colorScheme: ['silver', 'gray', 'blue', 'navy', 'fuchsia', 'red', 'red']
    }),
    JoyrideModule.forRoot(),
    LeafletModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoginModule,
    OnboardingModule,
    RegisterModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    SharedModule,
    NgxSkeletonLoaderModule,
    InlineSVGModule.forRoot(),
    LeafletMarkerClusterModule,
    MatNativeDateModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    CookieService,
    provideEnvironmentNgxMask(maskOptions),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
