import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { Injectable, Injector } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { API_BASE_URL, API_TOKEN } from 'src/api.conf';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { HydraoApiService } from 'src/app/core/services/core/hydrao-api.service';
import { HydraoResponseAdapter, HydraoResponse } from 'src/app/core/models/response.model';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class AccountService extends HydraoApiService<any> {

    constructor(
        injector: Injector,
        private _utils: UtilsService,
        private _responseAdapter: HydraoResponseAdapter) {
        super(injector);
    }



    public registerUser(email: string, password: string) {
        this.logger.info(`register user with mail ${email}`);

        return this.httpClient.post(API_BASE_URL + '/users', { email: email, password: password, locale: this._utils.locale }, {
            headers: new HttpHeaders({
                'x-api-key': API_TOKEN,
                'Content-Type': 'application/json'
            })
        }).pipe(catchError(err => {
            this.logger.warn('fatal fail ' + JSON.stringify(err));


            throw err;
        }));
    }

    public resendConfCode(email: string) {
        this.logger.info(`resend code to email ${email}`);

        return this.httpClient.post(API_BASE_URL + '/users/' + this._utils.toBase64Url(email) + '/resend-code', {
            headers: new HttpHeaders({
                'x-api-key': API_TOKEN,
                'Content-Type': 'application/json'
            })
        }).pipe(catchError(err => {
            this.logger.warn('fatal fail ' + JSON.stringify(err));
            throw err;
        }));
    }


    public confirmUser(email: string, code: string) {
        this.logger.info(`resend code to email ${email}`);

        return this.httpClient.post(API_BASE_URL + '/users/' + this._utils.toBase64Url(email) + '/confirm', { code: `${code}` }, {
            headers: new HttpHeaders({
                'x-api-key': API_TOKEN,
                'Content-Type': 'application/json'
            })
        }).pipe(catchError(err => {
            this.logger.warn('fatal fail ' + JSON.stringify(err));
            throw err;
        }));
    }

    public forgotPassword(email: string) {
        this.logger.info(`resend code to email ${email}`);

        return this.httpClient.post(API_BASE_URL + '/users/' + this._utils.toBase64Url(email) + '/forgot-password', null, {
            headers: new HttpHeaders({
                'x-api-key': API_TOKEN,
                'Content-Type': 'application/json'
            })
        }).pipe(catchError(err => {
            this.logger.warn('fatal fail ' + JSON.stringify(err));
            throw err;
        }));
    }


    public forgotPasswordConfirmation(email: string, code: string, password: string) {
        this.logger.info(`resend code to email ${email}`);
        let url = API_BASE_URL + `/users/${this._utils.toBase64Url(email)}/forgot-password-confirmation`;
        return this.httpClient.post(url, { code: code, password: password }, {
            headers: new HttpHeaders({
                'x-api-key': API_TOKEN,
                'Content-Type': 'application/json'
            })
        }).pipe(catchError(err => {
            this.logger.warn('fatal fail ' + JSON.stringify(err));
            throw err;
        }));
    }


    public changePassword(oldPass: string, newPass: string): Observable<HydraoResponse> {
        return this.postResponse('/users/me/change-password', { old_password: oldPass, new_password: newPass }, this._responseAdapter)
    }

    //

}