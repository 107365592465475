import { Injectable, Injector } from '@angular/core';

import { Observable, of, Subject, first } from 'rxjs';

import { DashboardConfig, User, UserAdapter } from '../models/user.model';
import { HydraoApiService } from './core/hydrao-api.service';
import { delayWhen, map, switchMap, tap } from 'rxjs/operators';
import { Settings } from 'luxon';





@Injectable({
  providedIn: 'root'
})
export class UserService extends HydraoApiService<User>{

  private _needUserChange = new Subject<User>();

  constructor(
    injector: Injector,
    private _userAdapter: UserAdapter
  ) {
    super(injector);

    this._needUserChange
      .pipe(first())
      .subscribe((user: User) => {
        this.logger.warn('user changed, empty cache and reload!');
        this.cacheService.clearAll()
          .pipe(map(() => {
            this.logger.info('Cache cleared');
            localStorage.setItem('login', user.email);
            location.reload();
            this.logger.info('done');
          })).subscribe();
      })

  }


  public getUserData(): Observable<User> {


    return (this.get('/users/me', this._userAdapter) as Observable<User>)
      .pipe(switchMap((user) => {
        let lastLogin = localStorage.getItem('login');
        if (lastLogin && lastLogin !== user.email) {
          this._needUserChange.next(user);
        } else {
          this.cacheService.inMemoryData.set('user', user);
          if (user.timezone) {
            Settings.defaultZone = user.timezone;
            // if (this._dateSelectorSvc.date.zoneName !== user.timezone) {
            //   this._dateSelectorSvc.changeDate(this._dateSelectorSvc.date.setZone(user.timezone), this._dateSelectorSvc.timeframe, this._dateSelectorSvc.placeId)
            // }
          }

          if (!user.dashboard) user.dashboard = new DashboardConfig(DashboardConfig.DEFAULT_METER_DASH, DashboardConfig.DEFAULT_SH_DASH);
          if (!user.dashboard.meter) user.dashboard.meter = DashboardConfig.DEFAULT_METER_DASH;
          if (!user.dashboard.showerhead) user.dashboard.showerhead = DashboardConfig.DEFAULT_SH_DASH;
          if (!user.water_unit) user.water_unit = 'L';
          if (!user.locale) {
            let browserLocale = navigator.language || (navigator as any).userLanguage;
            if (browserLocale == 'fr') {
              user.locale = 'fr';
            } else {
              user.locale = 'en';
            }
          }
        }
        return of(user);
      }))
      .pipe(map<User, User>((user: User) => {
        // if (!user.canViewMeters() && !user.canViewSh()) {
        //   this.logger.warn('user cannot access shower head or meter, access to my hydrao is useless');
        //   this.authService.logout();
        //   this.mySnackBar.open($localize`You do not have the right to access this website, please contact Hydrao support. `, $localize`OK`, {
        //     panelClass: ['error-snackbar']
        //   });
        // } else if (user.locale) {
        Settings.defaultLocale = user.locale;
        // }

        return user;
      }));
  }

  public saveUserData(user: User): Observable<User> {

    return this.put('/users/me', user.toObject(), this._userAdapter)
      .pipe(
        delayWhen((u: User) => {
          return this.cacheService.clear('/users/me')
            .pipe(tap(() => {
              this.cacheService.inMemoryData.set('user', u)
              if (u.timezone) {
                Settings.defaultZone = u.timezone;
              }

            }));
        }),
      ) as Observable<User>;
  }

}
