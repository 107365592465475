import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { DateTime, Settings } from 'luxon';
import { UserService } from 'src/app/core/services/user.service';
import { aryIannaTimeZones } from 'src/app/shared/components/timezone-select/timezones';

@Component({
  selector: 'hydrao-timezone-select',
  templateUrl: './timezone-select.component.html',
  styleUrls: ['./timezone-select.component.scss']
})
export class TimezoneSelectComponent implements OnInit {

  @Input()
  public timezone:string;

  @Output()
  public timezoneChange = new EventEmitter<string>();

  public timezones = aryIannaTimeZones.sort((a, b) => {
    if (a < b) { return -1; }
    if (a > b) { return 1; }
    return 0;
  });


  public date: string;

  constructor() {
    let browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (this.timezones.indexOf(browserTz) >= 0) this.timezone = browserTz;
    this.date = DateTime.local().toFormat('HH:mm \'UTC\' ZZ');
  }

  public ngOnInit(): void {
    this.timezoneChange.emit(this.timezone);
  }

  public onChange(timezone: MatSelectChange) {

    // Settings.defaultZoneName = timezone.value;
    this.date = DateTime.local().setZone(timezone.value).toFormat('HH:mm \'UTC\' ZZ');
    this.timezoneChange.emit(timezone.value);
  }

}
