import { Injectable } from '@angular/core';
import { Adapter } from 'src/app/core/adapter';
import { Model } from 'src/app/core/models/model';


export class Prelaunch implements Model {
    constructor(
        public maintenance: boolean,
        public min_version: string
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class PrelaunchAdapter implements Adapter<Prelaunch> {

    adapt(item: any): Prelaunch {
        return new Prelaunch(
            item.maintenance,
            item.min_version_web_app
        );
    }
}