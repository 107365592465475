<div class="form-wrapper">
    <mat-card appearance="outlined">
        <div class="hydrao-card-title" i18n>Confirm your account</div>
        <div class="intro-text" i18n>
            Your have to confirm your account. An email has been sent with a code to the email <b>{{email}}</b>. Please enter the code below. 
        </div>
        <form [formGroup]="confirmAccountForm" autocomplete="off" novalidate (ngSubmit)="confirmAccount(confirmAccountForm.value)"
            fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
            <mat-form-field>
                <input required matInput i18n-placeholder placeholder="Confirmation code" type="text" formControlName="confCode"
                    id="confCode" autofocus>
                <mat-error *ngIf="hasError('confCode', 'required')" i18n>Confirmation code is required</mat-error>
                <mat-error *ngIf="hasError('confCode', 'pattern')" i18n>Confirmation code should be 6 digits long.</mat-error>
            </mat-form-field>
          
            <div class="buttons">
                <button mat-flat-button color="primary" [disabled]="!confirmAccountForm.valid" i18n>confirm account</button>
                <button mat-button type="button" color="primary" i18n (click)="resendCode()">resend email</button>
            </div>
            <div class="error mat-error" *ngIf="errorCode" [ngSwitch]="errorCode">
                <div *ngSwitchCase="'CodeMismatch'" i18n>
                    The code you entered is invalid, please verify
                </div>
                <div *ngSwitchDefault i18n>
                    Unexpected error, please try again
                </div>
            </div>
        </form>
    </mat-card>
</div>