
<div class="login-page">
    <hydrao-admin-bar></hydrao-admin-bar>
    <div class="logo-container">
        <div class="hydrao-logo">
            <img src="assets/logo-smart.svg" />
        </div>
    </div>

    <button mat-flat-button class="logout-button" color="accent" (click)="logout()" [disabled]="logoutDisabled">
        <mat-icon>logout</mat-icon>
        <span i18n>logout</span>
    </button>
    <hydrao-stepper></hydrao-stepper>

</div>