import { Injectable } from '@angular/core';
import { combineLatest, concat, finalize, forkJoin, last, map, merge, Observable, of, switchMap, tap, zip } from 'rxjs';
import { Adapter } from 'src/app/core/adapter';
import { Model } from 'src/app/core/models/model';
import { User } from 'src/app/core/models/user.model';
import { UtilsService, ValueWithUnit } from 'src/app/shared/services/utils.service';

export class StatsElement implements Model {


    public volumeWithUnit: ValueWithUnit;
    public flowWithUnit: ValueWithUnit;
    public temperatureWithUnit: ValueWithUnit;


    public updateValuesWithUnits(): Observable<StatsElement> {

        return combineLatest([
            this.utilsService.getVolumeFormatted(this.volume)
                .pipe(tap((vol) => this.volumeWithUnit = vol)),
            this.utilsService.getFlowFormatted(this.flow)
                .pipe(tap((flow) => this.flowWithUnit = flow)),
            this.utilsService.getTemperatureFormatted(this.temperature)
                .pipe(tap((temp) => this.temperatureWithUnit = temp))
        ])
            .pipe(map(() => this));

    }

    public updateValuesWithUnitsWithUser(user:User ): void {
        this.volumeWithUnit = this.utilsService.getVolumeFormattedWithUser(user, this.volume);
        this.flowWithUnit = this.utilsService.getFlowFormattedWithUser(user, this.flow);
        this.temperatureWithUnit = this.utilsService.getTemperatureFormattedWithUser(user, this.temperature);
    }


    constructor(
        private utilsService: UtilsService,
        public volume: number,
        public volumeTrend: number,
        public flow: number,
        public flowTrend: number,
        public temperature: number,
        public temperatureTrend: number
    ) { }
}

export class ShowerHeadStats implements Model {
    constructor(
        public count: number,
        public stats: StatsElement
    ) { }
}

export class MeterStats implements Model {
    constructor(
        public count: number,
        public stats: StatsElement
    ) { }
}




export class PlaceStats implements Model {
    constructor(
        public global: StatsElement,
        public shower_head: ShowerHeadStats,
        public meter: MeterStats,
        public place_id: number
    ) { }

}



@Injectable({
    providedIn: 'root'
})
export class PlaceStatsAdapter implements Adapter<PlaceStats> {

    constructor(private utilsService: UtilsService) { }

    adapt(item: any): PlaceStats {
        return new PlaceStats(
            item.global ? new StatsElement(
                this.utilsService,
                item.global.volume,
                item.global.volume_trend,
                item.global.flow,
                item.global.flow_trend,
                item.global.temperature,
                item.global.temperature_trend
            ) : null,
            item.shower_head ? new ShowerHeadStats(
                item.shower_head.count,
                item.shower_head.stats ? new StatsElement(
                    this.utilsService,
                    item.shower_head.stats.volume,
                    item.shower_head.stats.volume_trend,
                    item.shower_head.stats.flow,
                    item.shower_head.stats.flow_trend,
                    item.shower_head.stats.temperature,
                    item.shower_head.stats.temperature_trend
                ) : null
            ) : null,
            item.meter ? new MeterStats(
                item.meter.count,
                item.meter.stats ? new StatsElement(
                    this.utilsService,
                    item.meter.stats.volume,
                    item.meter.stats.volume_trend,
                    item.meter.stats.flow,
                    item.meter.stats.flow_trend,
                    item.meter.stats.temperature,
                    item.meter.stats.temperature_trend
                ) : null
            ) : null,
            item.place_id ? item.place_id : null
        );
    }
}