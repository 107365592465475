import { Injectable, Injector } from '@angular/core';

import { delayWhen, forkJoin, map, Observable, of, switchMap } from 'rxjs';


import { Place, PlaceAdapter } from '../models/place.model';
import { HydraoApiService } from './core/hydrao-api.service';
import { NGXLogger } from 'ngx-logger';





@Injectable({
  providedIn: 'root'
})
export class PlaceService extends HydraoApiService<Place>{

  constructor(
    injector: Injector,
    private placeAdapter: PlaceAdapter,
    private _logger: NGXLogger) {
    super(injector);
  }

  /**
   * Empty place list cache. 
   * @param toplevel Top level webservice?
   */
  public emptyPlacesCache(toplevel: boolean): Observable<void> {
    let url = '/places'
    if (toplevel) {
      url += '?topLevel=true';
    }
    return this.cacheService.clear(url);
  }

  public emptyCache() {
    return forkJoin([
      this.emptyPlacesCache(true),
      this.emptyPlacesCache(false)
    ]);
  }

  public emptyPlaceAnomaliesCache(): Observable<void> {
    return this.cacheService.clear('/meters/place-anomalies');
  }

  /**
   * Return places
   */
  public getPlaces(toplevel: boolean, refresh: boolean = false): Observable<Place[]> {

    let url = '/places'
    if (toplevel) {
      url += '?topLevel=true';
    }

    if (refresh) {
      //clear all caches
      return forkJoin([
        this.emptyPlacesCache(true),
        this.emptyPlacesCache(false)
      ])
        .pipe(switchMap(() => this.get(url, this.placeAdapter) as Observable<Place[]>))
    } else {
      this._logger.info('returning place observable');
      return this.get(url, this.placeAdapter) as Observable<Place[]>;
    }



  }

  /**
   * get toplevel places with anomalies
   */
  public getPlaceAnomalies(): Observable<Place[]> {
    let url = '/meters/place-anomalies';
    return this.get(url, this.placeAdapter) as Observable<Place[]>;
  }

  /**
  * Return places
  * @param placeId : the placa id. 
  */
  public getPlace(placeId: number, refresh: boolean = false): Observable<Place> {
    let url = '/places/' + placeId;
    if (refresh) {
      return this.cacheService.clear(url)
        .pipe(switchMap(() => this.get(url, this.placeAdapter) as Observable<Place>));
    } else {
      return this.get(url, this.placeAdapter) as Observable<Place>;
    }

  }





  /**
   * Post new place
   * @param place The place
   */
  public postPlace(place: Place): Observable<Place> {

    return this.post('/places', place, this.placeAdapter)
      .pipe(delayWhen(() => this.emptyCache()));
  }

  /**
   * Modify existing place
   * @param place The place
   */
  public putPlace(place: Place): Observable<Place> {

    return this.put('/places/' + place.id, place, this.placeAdapter).pipe(delayWhen(() => this.emptyCache()));
  }

  /**
   * Delete place
   * @param placeId The place id
   */
  public deletePlace(placeId: number) {

    return this.delete('/places/' + placeId).pipe(delayWhen(() => this.emptyCache()));;
  }


  public computePlaceLabelFromId(placeId: number): Observable<string> {
    if (!placeId) return of($localize`no place`);

    let parentPlaces: Place[] = [];
    let findPlaceInArray = (places: Place[], placeId: number) => {
      let foundPlace = null;
      for (let i = 0; i < places.length && !foundPlace; i++) {
        if (places[i].id == placeId) {
          foundPlace = places[i];

        } else if (places[i].children) {
          foundPlace = findPlaceInArray(places[i].children, placeId);
          if (foundPlace) {
            parentPlaces.unshift(places[i]);
          }

        }
      }
      return foundPlace;
    };

    return this.getPlaces(false, false)
      .pipe(map((places) => {
        let place = findPlaceInArray(places, placeId);
        if (place) {
          let placeName = '';
          for (let curPl of parentPlaces) {
            placeName += curPl.label + ' / ';
          }
          placeName += place.label;
          return placeName;
        } else {
          return $localize`no place`;
        }
      }));




  }


}
