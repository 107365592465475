import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';


import { LoginComponent } from './components/login/login.component';
import { LoginPageComponent } from './screens/login-page/login-page.component';
import { SharedModule } from '../shared/shared.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ForgotPasswordComponent } from './screens/forgot-password/forgot-password.component';
import { ForgotPasswordConfirmationComponent } from './screens/forgot-password-confirmation/forgot-password-confirmation.component';
import { MaintenanceModeComponent } from 'src/app/login/screens/maintenance-mode/maintenance-mode.component';



@NgModule({
  declarations: [
    LoginComponent,
    LoginPageComponent,
    ForgotPasswordComponent,
    ForgotPasswordConfirmationComponent,
    MaintenanceModeComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatCardModule,
    SharedModule,
    MatProgressSpinnerModule
  ]
})
export class LoginModule { }
