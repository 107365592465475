
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'src/app/app.module';
import { environment } from 'src/environments/environment';

//import { TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';


if (environment.production) {
  enableProdMode();
}
// if (localStorage.getItem('locale') === null) {
//   let locale = navigator.language;
//   if (locale) {
//     locale = locale.split('-')[0];
//   }
//   console.log(`locale not set, use browser locale (${locale})`);
//   localStorage.setItem('locale', locale == 'fr' ? 'fr' : 'en');
// }

// const locale = localStorage.getItem('locale');
// declare const require;
// const translations = require(`raw-loader!./locale/messages.${locale}.xlf`).default;

// platformBrowserDynamic().bootstrapModule(AppModule, {
//   providers: [
//     { provide: TRANSLATIONS, useValue: translations },
//     { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' }
//   ]
// })
//   .catch(err => console.error(err));

platformBrowserDynamic().bootstrapModule(AppModule);
