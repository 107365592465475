import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpHeaderResponse,
    HttpSentEvent,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, switchMap, filter, take, finalize } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { API_TOKEN } from 'src/api.conf';
import { AuthService } from 'src/app/core/services/auth.service';

//see http://ericsmasal.com/2018/07/02/angular-6-with-jwt-and-refresh-tokens-and-a-little-rxjs-6/
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        private logger: NGXLogger) {

    }

    isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {

        return next.handle(this.addTokenToRequest(request, this.authService.token))
            .pipe(
                catchError(err => {
                    if (err instanceof HttpErrorResponse && !request.url.endsWith('/sessions')) {
                        switch ((<HttpErrorResponse>err).status) {
                            case 401:
                                return this.handle401Error(request, next);
                            default:
                                return throwError(() => err);
                        }
                    } else {
                        return throwError(() => err);
                    }
                }));
    }

    private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
        let headers = {
            Authorization: `Bearer ${token}`,
            'x-api-key': API_TOKEN,
            'Content-Type': 'application/json'
        };
        if (this.authService.adminBehalf) {
            headers['X-On-Behalf'] = this.authService.adminBehalf;
        }
        return request.clone({
            setHeaders: headers
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        this.logger.debug('handling 401');
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return this.authService.refreshConnection()
                .pipe(
                    switchMap(() => {
                        this.tokenSubject.next(this.authService.token);
                        return next.handle(this.addTokenToRequest(request, this.authService.token));
                    }),
                    catchError(err => {
                        return throwError(() => err);
                    }),
                    finalize(() => {
                        this.isRefreshingToken = false;
                        this.logger.debug('token refreshed');
                    })
                );
        } else {
            this.logger.debug('token refreshing, wait.');
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap((token: string) => {
                    this.logger.debug('retry request.');
                    return next.handle(this.addTokenToRequest(request, token));
                }));
        }
    }
}