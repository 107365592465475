import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject, throwError, BehaviorSubject, from } from 'rxjs';
import { finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { DateTime } from 'luxon';
import { Model } from 'src/app/core/models/model';
import { DbService } from 'src/app/shared/services/db.service';

/**
 * Cache Service is an observables based in-memory cache implementation
 * Keeps track of in-flight observables and sets a default expiry for cached values
 * @export
 * @class CacheService
 */
@Injectable({ providedIn: 'root' })
export class CacheService {
    private static _REFRESH_AFTER_SEC = 180;
    private static get _DEBUG() {
        return typeof window['__DEBUG_CACHE'] !== "undefined";
    }
    //private cache: Map<string, object> = new Map<string, object>();
    //private refreshedCache: Set<string> = new Set<string>();

    public get loading(): boolean {
        return this._loadingCount > 0;
    }
    public cacheCleared = new EventEmitter<void>();

    public loadingChange = new EventEmitter<boolean>();


    private _inFlightObservables: Map<string, Subject<any>> = new Map<string, Subject<any>>();
    private _loadingCount = 0;
    private _loaded = new Map<string, DateTime>();



    //The map is a utility dedicated to store in memory data to avoid waiting for async calls. 
    public inMemoryData = new Map<string, Model>();


    constructor(protected logger: NGXLogger, private _dbService: DbService) { }



    /**
      * Clear specific cache data
      * @param key The key
      */
    public clear(key: string): Observable<void> {
        return from((async () => {
            await this._dbService.clearCacheEntry(key);
        })());
    }
    /**
     * empty all caches
     */
    public clearAll(): Observable<void> {
        this.inMemoryData = new Map<string, Model>();


        return from((async () => {
            await this._dbService.clearAllCache();
          })());
    }



    public registerLoading() {
        this._loadingCount++;
    }

    public unregisterLoading() {
        this._loadingCount--;
    }

    /**
     * Gets the value from cache if the key is provided.
     * If no value exists in cache, then check if the same call exists
     * in flight, if so return the subject. If not create a new
     * Subject inFlightObservable and return the source observable.
     */
    public get(key: string, fallback: Observable<any>, refreshDelay = CacheService._REFRESH_AFTER_SEC): Observable<any> {
        try {
            if (CacheService._DEBUG) this.logger.debug(`[CACHE] manage request for ${key}`);
            return this._getByKey(key)
                .pipe(switchMap(c => {
                    if (c) {
                        if (CacheService._DEBUG) this.logger.debug(`[CACHE] value found in cache for ${key}`);
                        let subject = new BehaviorSubject(JSON.parse(c['data']));
                        this._inFlightObservables.set(key, subject);

                        this.loadingChange.emit(this.loading);
                        let shouldLoadInBg = false;

                        if (!this._loaded.has(key)) {
                            if (CacheService._DEBUG) this.logger.debug(`[CACHE] value never loaded for ${key}, loading it in bg.`)
                            shouldLoadInBg = true;
                        } else {
                            const elapsed = DateTime.local().diff(this._loaded.get(key), 'seconds').seconds;
                            const expiration = (refreshDelay >= 0 ? refreshDelay : CacheService._REFRESH_AFTER_SEC);
                            if (elapsed > expiration) {
                                if (CacheService._DEBUG) this.logger.debug(`[CACHE] cache is ${elapsed}s old, wich is more than max ${expiration}s allowed. bg refresh for ${key}`)
                                shouldLoadInBg = true;
                            } else {
                                shouldLoadInBg = false;
                                if (CacheService._DEBUG) this.logger.debug(`[CACHE] cache is ${elapsed}s old, wich is less than max ${expiration}s allowed. Do nothing for ${key}`)
                            }

                        }


                        if (shouldLoadInBg) {
                            if (CacheService._DEBUG) this.logger.debug('[CACHE] [BG]loading+' + this._loadingCount + ' for ' + key);
                            this._loaded.set(key, DateTime.local());
                            this._loadingCount++;
                            // console.log('=======++> CALL ' + key);
                            fallback
                                .pipe(first())
                                .pipe(finalize(() => {
                                    this._loadingCount--;
                                    if (CacheService._DEBUG) this.logger.debug('[CACHE] [BG] loading-' + this._loadingCount + ' for ' + key);
                                    this.loadingChange.emit(this.loading);
                                }))
                                .subscribe(async (value) => {
                                    // console.log('=======++> THEN ' + key);
                                    await this._dbService.writeCache({ data: JSON.stringify(value) }, key);

                                    this._notifyInFlightObservers(key, value);
                                })
                            return this._inFlightObservables.get(key);

                        } else {
                            //nothing else to do, remove from in flight observables. 
                            if (CacheService._DEBUG) this.logger.debug(`[CACHE] Nothing to do, removing from inFlightObservable ${key}`);
                            let inFlight = this._inFlightObservables.get(key);
                            this._inFlightObservables.delete(key);
                            return inFlight;
                        }

                        // this.logger.info('return from db');
                    } else if (this._inFlightObservables.has(key)) {
                        if (CacheService._DEBUG) this.logger.debug(`[CACHE] Return inflight observable for ${key}`);
                        return this._inFlightObservables.get(key);
                    } else if (fallback && fallback instanceof Observable) {
                        this._inFlightObservables.set(key, new Subject());
                        if (CacheService._DEBUG) this.logger.info(`[CACHE] Value not in cache, calling api for ${key}`);
                        this._loadingCount++;
                        if (CacheService._DEBUG) this.logger.debug('[CACHE] [FG] loading+' + this._loadingCount);
                        this.loadingChange.emit(this.loading);
                        fallback
                            //.pipe(finalize())
                            //.pipe(catchError())
                            .pipe(first())
                            .subscribe({
                                next: (value) => {
                                    if (CacheService._DEBUG) this.logger.debug('[CACHE] storing val in cache...');
                                    this.set(key, value)
                                        .pipe(first())
                                        .subscribe(() => {
                                            if (CacheService._DEBUG) this.logger.debug(`[CACHE] ...value stored in cache for ${key}`);
                                            this._loaded.set(key, DateTime.local());
                                        });
                                },
                                error: (err) => {
                                    if (CacheService._DEBUG) this.logger.error('[CACHE] ws error');
                                    this._notifyErrInFlightObservers('key', err);
                                    this._loadingCount--;
                                    if (CacheService._DEBUG) this.logger.debug('[CACHE] [FG] loading-' + this._loadingCount + ' for ' + key);
                                    this.loadingChange.emit(this.loading);
                                    if (this._inFlightObservables.get(key))
                                        this._inFlightObservables.get(key).error(err);
                                    //throw err;
                                },
                                complete: () => {
                                    this._loadingCount--;
                                    if (CacheService._DEBUG) this.logger.debug('[CACHE] [FG] loading-' + this._loadingCount + ' for ' + key);
                                    this.loadingChange.emit(this.loading);
                                }
                            });
                        return this._inFlightObservables.get(key);
                    } else {
                        if (CacheService._DEBUG) this.logger.error('[CACHE] no fallback, should not happen !');
                        return throwError(() => new Error('Requested key is not available in Cache'));
                    }
                }));
        }
        catch (e) {
            console.error(e)
        }



    }

    /**
     * Sets the value with key in the cache
     * Notifies all observers of the new value
     */
    public set(key: string, value: any): Observable<any> {

        return from((async () => {
            await this._dbService.writeCache({ data: JSON.stringify(value) }, key);
            this._notifyInFlightObservers(key, value);
            return value;
        })());


    }

    /**
     * Checks if the a key exists in cache
     */
    public has(key: string): Observable<boolean> {
        return this._getByKey(key).pipe(map(e => e ? true : false));
    }


    private _getByKey(key: string): Observable<any> {
        return from((async () => {
            return await this._dbService.getCache(key);
        })());
    }

    /**
     * Publishes the value to all observers of the given
     * in progress observables if observers exist.
     */
    private _notifyInFlightObservers(key: string, value: any): void {
        if (this._inFlightObservables.has(key)) {
            const inFlight = this._inFlightObservables.get(key);
            //const observersCount = inFlight.observers.length;
            if (inFlight.observed) {
                if (CacheService._DEBUG) this.logger.info(`[CACHE] Notifying subscribers for ${key}`);
                inFlight.next(value);
            }
            inFlight.complete();
            this._inFlightObservables.delete(key);
        }
    }


    /**
    * Publishes the value to all observers of the given
    * in progress observables if observers exist.
    */
    private _notifyErrInFlightObservers(key: string, err: any): void {
        if (this._inFlightObservables.has(key)) {
            const inFlight = this._inFlightObservables.get(key);
            //const observersCount = inFlight.observers.length;
            if (inFlight.observed) {
                if (CacheService._DEBUG) this.logger.info(`[CACHE] Notifying subscribers for ${key}`);
                inFlight.error(err);
            }
            inFlight.complete();
            this._inFlightObservables.delete(key);
        }
    }
}
