import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { AccountService } from 'src/app/core/services/accounts/account.service';

@Component({
  selector: 'hydrao-forgot-password-confirmation',
  templateUrl: './forgot-password-confirmation.component.html',
  styleUrls: ['./forgot-password-confirmation.component.scss']
})
export class ForgotPasswordConfirmationComponent implements OnInit {


  public forgotPassForm: UntypedFormGroup;
  public errorCode: string = null;
  public email: string = null;
  public loading = false;
  public passChanged = false;

  constructor(
    private _logger: NGXLogger,
    private _accountService: AccountService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _route: ActivatedRoute

  ) { }

  public ngOnInit() {

    let emailBase64 = this._route.snapshot.paramMap.get('emailBase64');
    if (emailBase64) {
      try {
        this.email = atob(emailBase64);
      }
      catch (e) {
        this._logger.warn('cannot decode email ');
        this.email = null;
      }
    }

    this.forgotPassForm = new UntypedFormGroup({
      email: new UntypedFormControl({ value: this.email, disabled: this.email != null }, [Validators.required, Validators.email]),
      code: new UntypedFormControl('', [Validators.required]),
      pass: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      passConf: new UntypedFormControl('', [Validators.required])
    }, MustMatch('pass', 'passConf'));
  }


  public hasError(controlName: string, errorName: string) {
    return this.forgotPassForm.controls[controlName].hasError(errorName);
  }

  public async submit(formValue: any) {

    if (this.forgotPassForm.valid) {
      this.errorCode = null;
      this._logger.debug(`login with ${formValue.email}`);
      let code = formValue.code; 
      let pass = formValue.pass;
      try {
        this.loading = true;
        // await lastValueFrom(this.accountService.registerUser(email, loginFormValue.pass));
        this._accountService.forgotPasswordConfirmation(this.email,code, pass).subscribe({
          next: () => {
            this._logger.info('forgo pass request successfull');
            this.loading = false;
            this.passChanged = true;
          }, error: (err) => {
            if (err && err.error && err.error.error == 'UserNotFound') {
              this.forgotPassForm.controls.email.setErrors({ userNotFound: true })
            } else if(err && err.error && err.error.error == 'CodeMismatch') {
              this.forgotPassForm.controls.email.setErrors({ wrongCode: true })
            } else {
              this._snackBar.open($localize`unexpected error`, $localize`RETRY`, {
                panelClass: ['error-snackbar']
              }).onAction().subscribe(() => {
                this.submit(formValue);
              });
            }
            this.loading = false;
          }
        });

      } catch (err) {
        this.errorCode = err ? err.error ? err.error.error : null : null;
      };
    }
  }

  public goBack() {
    history.back();
  }

  public goToLogin() {
    this._router.navigate(['/']);
  }



}

function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
    return null;
  };
}
