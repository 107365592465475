import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Prelaunch } from 'src/app/core/models/prelaunch.model';
import { PrelaunchService } from 'src/app/core/services/prelaunch.service';
import { VERSION } from 'src/environments/version';

@Component({
  selector: 'hydrao-maintenance-mode',
  templateUrl: './maintenance-mode.component.html',
  styleUrl: './maintenance-mode.component.scss'
})
export class MaintenanceModeComponent {

  public get version () {
    return VERSION.version;
  }

  public get hash () {
    return VERSION.hash;
  }

  public get branch () {
    return VERSION.branch;
  }


  constructor(
    private _prelaunchService: PrelaunchService,
    private _router: Router,

  ) {

    _prelaunchService.check().subscribe((prelaunch: Prelaunch) =>{
      if(!prelaunch.maintenance) {
        _router.navigate(['/'], { replaceUrl: true });
      }
    });

  }

}
