import { Injectable } from '@angular/core';
import { Adapter } from 'src/app/core/adapter';
import { MeterMetricId, ShowerHeadMetricId } from 'src/app/core/models/global-stats.model';
import { Model } from 'src/app/core/models/model';



export enum OnBoardingSection {
    General = 'web_general',
    Places = 'web_places',
    Alerts = 'web_alerts',
    ShowerHead = 'web_sh',
    Meter = 'web_meter'
}

export class DashboardConfig implements Model {

    public static DEFAULT_METER_DASH = [MeterMetricId.meter_volume];
    public static DEFAULT_SH_DASH = [ShowerHeadMetricId.volume, ShowerHeadMetricId.avg_shower, ShowerHeadMetricId.total_shower_per_day];

    constructor(
        public meter: string[],
        public showerhead: string[]
    ) {

    }
}

export class CustomPages implements Model {
    constructor(
        public url: string,
        public label: string
    ) {

    }
}


export class User implements Model {
    constructor(
        public id: string,
        public email: string,
        public features: Set<string>,
        public dashboard: DashboardConfig,
        public locale: string,
        public timezone: string,
        public water_unit: string,
        public min_shower_liter: number,
        public cell_phone: string,
        public onboarding: OnBoardingSection[],
        public custom_pages: CustomPages[]
    ) { }

    public get my_locale() {
        if (!this.locale) return 'en';
        let locale = this.locale.toLowerCase().substring(0, 2);
        if (locale == 'fr') {
            return 'fr';
        } else {
            return 'en';
        }
    }

    public canViewPlaces = () => this.features != null && this.features.has('web_view_places');
    public canViewMeters = () => this.features != null && this.features.has('web_view_meters');
    public canViewMeterUsage = () => this.features != null && this.features.has('meter_labelling');
    public canViewSh = () => this.features != null && this.features.has('web_view_sh');
    public canViewAlerts = () => this.features != null && this.features.has('web_view_alerts');
    public canAddSh = () => this.features != null && this.features.has('web_add_sh_by_serial');
    public canChangeThreshold = () => this.features != null && this.features.has('web_change_threshold');
    public canManageChildAccounts = () => this.features != null && this.features.has('web_request_child_account');
    public canCreateSmartAlerts = () => this.features != null && this.features.has('smart_alerts');
    public canNotifySMS = () => this.features != null && this.features.has('sms_notifications');
    public canViewMeterHourlySignal = () => this.features != null && this.features.has('meter_hourly_signal');
    public canViewGateways = () => this.features != null && this.features.has('web_view_gateways');
    public hasCustomPages = () => this.custom_pages != null && this.custom_pages.length > 0;


    public isAdmin = () => this.features != null && this.features.has('admin');

    public useGallons = () => this.water_unit != null && this.water_unit !== 'L';
    public useLiters = () => !this.useGallons();


    public disableOnBoarding(section: OnBoardingSection) {
        let index = this.onboarding.indexOf(section);
        if (index >= 0) this.onboarding.splice(index, 1);
    }

    public resetOnboarding() {
        this.onboarding = [];
        for (let val of Object.values(OnBoardingSection)) {
            this.onboarding.push(val);
        }
    }

    public enableShowerHeadFeature() {
        this.features.add('web_view_sh');
        this.features.add('web_change_threshold');
    }

    public disableShowerHeadFeature() {
        this.features.delete('web_view_sh');
    }

    public enableMeterFeature() {
        this.features.add('web_view_meters');
        this.features.add('web_view_places');
        this.features.add('web_view_alerts');
        this.features.add('web_view_gateways');
    }

    public disableMeterFeature() {
        this.features.delete('web_view_meters');
        this.features.delete('web_view_alerts');
    }

    public enablePlaceFeature() {
        this.features.add('web_view_places');
        this.features.add('web_add_sh_by_serial');
    }

    public disablePlaceFeature() {
        this.features.delete('web_view_places');
    }


    public toObject(): any {
        return {
            id: this.id,
            email: this.email,
            features: Array.from(this.features),
            dashboard: this.dashboard,
            locale: this.locale,
            timezone: this.timezone,
            water_unit: this.water_unit,
            min_shower_liter: this.min_shower_liter,
            cell_phone: this.cell_phone,
            onboarding: this.onboarding
        };
    }



}



@Injectable({
    providedIn: 'root'
})
export class UserAdapter implements Adapter<User> {

    adapt(item: any): User {
        return new User(
            item.id,
            item.email,
            new Set(item.features),
            item.dashboard ? new DashboardConfig(item.dashboard.meter, item.dashboard.showerhead) : new DashboardConfig([], []),
            item.locale,
            item.timezone,
            item.water_unit,
            item.min_shower_liter,
            item.cell_phone,
            item.onboarding,
            item.additional_pages ? item.additional_pages.map((i) => new CustomPages(i.url, i.label)) : []
        );
    }
}