import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingComponent } from './screens/onboarding/onboarding.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { StepperComponent } from './components/stepper/stepper.component';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { TimezoneFirstSelectComponent } from './screens/timezone-first-select/timezone-first-select.component';



@NgModule({
  declarations: [
    OnboardingComponent,
    StepperComponent,
    TimezoneFirstSelectComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatRadioModule,
    MatCheckboxModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    SharedModule
  ]
})
export class OnboardingModule { }
