import { AfterViewInit, Component, DoCheck, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LatLng, latLng, MapOptions, tileLayer, Map, MarkerClusterGroup, MarkerClusterGroupOptions, Marker, marker, divIcon, MarkerCluster, DivIcon, LatLngBounds, FitBoundsOptions, featureGroup } from 'leaflet';


export class LatLon {
  public lat: number;
  public lon: number;
  public constructor(lat: number, lon: number) {
    this.lat = lat;
    this.lon = lon;
  }
}

export interface IBubbleElement {
  id: number;
  size: number;
  coordinates: LatLon;
  label: string;
}


@Component({
  selector: 'hydrao-bubble-map',
  templateUrl: './bubble-map.component.html',
  styleUrls: ['./bubble-map.component.scss']
})
export class BubbleMapComponent implements OnChanges, DoCheck {


  @Input()
  public bubbles: IBubbleElement[];



  @Input()
  public selectedBubbleId: number = null;

  @Input()
  public clustering: boolean = true;

  @Input()
  public dispIcon: boolean = true;






  @Output()
  public visibleBubblesChange: EventEmitter<IBubbleElement[]> = new EventEmitter<IBubbleElement[]>();

  @Output()
  public selectedBubbleIdChange: EventEmitter<number> = new EventEmitter<number>();

  public visibleBubbles: IBubbleElement[];

  public mapOptions: MapOptions;
  public selectedBubble: LatLng = null;
  public map: Map;
  mapFitToBounds: LatLngBounds;

  private _maxSize = 0;
  private _markerGroup: MarkerClusterGroup;

  public markerClusterOptions: MarkerClusterGroupOptions = {
    animateAddingMarkers: true,
    animate: true,
    iconCreateFunction: (cluster: MarkerCluster) => {
      const markers: Marker[] = cluster.getAllChildMarkers();
      let sizes = markers.map((m) => {
        let b = this.bubbles.find(b => b.id == m.options['id']);
        return b.size;

      });
      const totalSize = sizes.reduce((a, b) => a + b);
      let icSize = (totalSize / (markers.length * this._maxSize)) * 200; //double size to make it bigger than markers
      if (icSize < 30) icSize = 30;
      return divIcon({
        className: 'bubble-cluster',
        html: `<div style="width:${icSize - 10}px; height:${icSize - 10}px;line-height:${icSize - 10}px; margin: 5px; "><span> ${markers.length}</span></div>`,
        iconSize: [icSize, icSize]
      });
    },
    polygonOptions: {
      className: 'bubble-polygon'
    }
  };
  public markerClusterData: Marker[] = [];

  public mapFitToBoundsOptions: FitBoundsOptions = { maxZoom: 12, animate: true, padding: [20, 20] };


  private _hostElement: HTMLElement;
  private _hostWidth: number = 0;
  private _hostHeight: number = 0;

  private _markers: Marker[] = [];


  //https://www.d3-graph-gallery.com/graph/bubblemap_leaflet_basic.html
  //https://esri.github.io/esri-leaflet/examples/styling-clusters.html
  //https://github.com/Leaflet/Leaflet.markercluster

  constructor(private _elRef: ElementRef) {
    // this.selectedBubble = latLng(39.9254904, -101.3397712);

    this.mapOptions = {
      layers: [
        tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 18,
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        })
      ],
      zoom: 4,
      center: new LatLng(45.18515197288288, 5.7049403680515764)
      //zoom: 5,
      //tap: false,
    };
  }

  public ngDoCheck(): void {
    if (this._hostWidth !== this._elRef.nativeElement.offsetWidth || this._hostHeight !== this._elRef.nativeElement.offsetHeight) {
      this._hostWidth = this._elRef.nativeElement.offsetWidth;
      this._hostHeight = this._elRef.nativeElement.offsetHeight;
      this._sizeChanged();
    }
  }



  public ngOnChanges(changes: SimpleChanges): void {


    if (changes.bubbles && changes.bubbles.currentValue) {
      if (this.bubbles.length > 0) {
        this._maxSize = this.bubbles.map((b) => b.size).reduce((a, b) => a > b ? a : b);
        this._refreshMarkers(changes.bubbles.previousValue, changes.bubbles.currentValue);
        this._refreshVisibleBubbles();
      } else {
        this.markerClusterData = [];
      }


    }

    if (changes.selectedBubbleId) {
      this._refreshDispFromSelectedId();
    }

  }




  public onMapReady(map: Map) {
    this.map = map;
  }


  public onMarkerClusterReady(markerCluster: MarkerClusterGroup) {
    // Do stuff with group
  }


  public onMoveEnd(map: Map) {
    this._refreshVisibleBubbles();
  }
  public onZoomEnd(map: Map) {
    this.selectedBubbleId = null;
    this.selectedBubbleIdChange.emit(this.selectedBubbleId);
    this._refreshDispFromSelectedId();
    this._refreshVisibleBubbles();
  }

  private _refreshVisibleBubbles() {
    if (this.map && this.bubbles) {
      this.visibleBubbles = []
      for (let bubble of this.bubbles) {
        let latLong: LatLng = new LatLng(bubble.coordinates.lat, bubble.coordinates.lon);
        if (this.map.getBounds().contains(latLong)) {
          this.visibleBubbles.push(bubble);
        }
      }

      this.visibleBubblesChange.emit(this.visibleBubbles);
    }
  }



  private _updateIconFromBubble(icon: DivIcon, bubble: IBubbleElement): void {
    if (this._maxSize < 1) this._maxSize = 1; //avoid zero division
    let iconSize = (bubble.size * 100) / this._maxSize;
    if (iconSize < 30) iconSize = 30; //do not allow too small circles
    const fontSize = iconSize < 40 ? Math.round(iconSize - 10) : 30;
    let selected = false;
    if (this.selectedBubbleId === bubble.id) {
      selected = true;
    }
    icon.options.className = 'bubble-marker' + (selected ? ' selected' : '');
    if (this.dispIcon) {
      icon.options.html = '<div class="mat-icon material-icons mat-icon-no-color" style="vertical-align:super;font-size: ' + fontSize + 'px; width: ' + fontSize + 'px;line-height:20px">location_city</div>';
    } else {
      icon.options.html = '<div></div>'
    }


    icon.options.iconSize = [iconSize, iconSize];
  }

  private _createMarkerFromBubble(bubble: IBubbleElement): Marker {

    //display even unlocalized data
    if (!bubble.coordinates.lat) bubble.coordinates.lat = 0;
    if (!bubble.coordinates.lon) bubble.coordinates.lon = 0;

    let ic: DivIcon = new DivIcon();
    this._updateIconFromBubble(ic, bubble);
    let mark = marker([

      bubble.coordinates.lat, bubble.coordinates.lon],
      {
        icon: ic
      }
    );
    mark.options['size'] = bubble.size;
    mark.options['id'] = bubble.id;
    mark.on('click', event => {
      this.selectedBubbleId = bubble.id;
      this.selectedBubbleIdChange.emit(this.selectedBubbleId);
      this._refreshDispFromSelectedId();

    });
    return mark;
  }

  private _updateMarkerFromBubble(bubble: IBubbleElement) {

    let marker = this.markerClusterData.find(m => m.options['id'] == bubble.id);
    if (marker) {
      if (!bubble.coordinates.lat) bubble.coordinates.lat = 0;
      if (!bubble.coordinates.lon) bubble.coordinates.lon = 0;

      this._updateIconFromBubble(marker.getIcon(), bubble);
      //marker.setIcon(this._getIconFromBubble(bubble));
      marker.setLatLng(new LatLng(bubble.coordinates.lat, bubble.coordinates.lon));
      return true;
    } else {
      return false;
    }


  }

  private _refreshMarkers(oldBubbles: IBubbleElement[], newBubbles: IBubbleElement[]) {

    if (this.clustering) {
      let ajustZoom: boolean = false;
      if (!newBubbles || newBubbles.length == 0) {
        this.markerClusterData = [];
      } else if (!oldBubbles || oldBubbles.length != newBubbles.length) {
        this.markerClusterData = this.bubbles
          .map((b) => {
            return this._createMarkerFromBubble(b);
          })
          .filter((m) => m.getLatLng() != null);
        ajustZoom = true;
      } else {
        if (!this.markerClusterData) this.markerClusterData = [];
        for (let bubble of newBubbles) {
          if (!this._updateMarkerFromBubble(bubble)) {
            this.markerClusterData.push(this._createMarkerFromBubble(bubble))
            ajustZoom = true;
          }
        }

      }

      if (ajustZoom) {

        this._markerGroup = new MarkerClusterGroup();

        for (let marker of this.markerClusterData) {
          this._markerGroup.addLayer(marker);
        }

        this.mapFitToBounds = this._markerGroup.getBounds();

      }
    } else {
      if (this.bubbles && this.map) {

        this._markers.forEach(m => this.map.removeLayer(m));

        for (let bubble of this.bubbles) {
          this._markers.push(this._createMarkerFromBubble(bubble)
            .addTo(this.map));
          //this.mapOptions.layers.push(this._createMarkerFromBubble(bubble));
        }

        var group = featureGroup(this._markers);
        this.mapFitToBounds = group.getBounds();
      }


    }

  }

  private _refreshDispFromSelectedId() {
    for (let clusterData of this.markerClusterData) {
      let icon: DivIcon = clusterData.getIcon();
      if (clusterData.options['id'] == this.selectedBubbleId) {
        clusterData.setIcon(divIcon({
          className: 'bubble-marker selected',
          html: icon.options.html,
          iconSize: icon.options.iconSize
        }));
      } else {
        clusterData.setIcon(divIcon({
          className: 'bubble-marker',
          html: icon.options.html,
          iconSize: icon.options.iconSize
        }));
      }
    }
  }

  private _sizeChanged(): void {
    if (this.map) this.map.invalidateSize(true);
  }





}
