import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { AccountService } from 'src/app/core/services/accounts/account.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.scss']
})
export class ConfirmAccountComponent implements OnInit {

  public confirmAccountForm: UntypedFormGroup;
  public errorCode: string = null;
  public email: string = localStorage.getItem('login');



  constructor(
    private logger: NGXLogger,
    private accountService: AccountService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {

  }

  public ngOnInit(): void {
    this.confirmAccountForm = new UntypedFormGroup({
      confCode: new UntypedFormControl('', [Validators.required, Validators.pattern(/^[0-9]{6}$/)])
    });
  }

  public hasError(controlName: string, errorName: string) {
    return this.confirmAccountForm.controls[controlName].hasError(errorName);
  }

  public confirmAccount(confFormValue) {
    if (this.confirmAccountForm.valid) {
      this.errorCode = null;
      this.logger.debug(`login with ${confFormValue.confCode}`);
      this.accountService.confirmUser(this.email, confFormValue.confCode)
        .subscribe(
          arg => {
            this.logger.info('done');
            this.router.navigate(['/register-conf']);
          },
          err => {
            this.errorCode = err ? err.error ? err.error.error : null : null;
          }
        );
   

    }
  }
  public resendCode() {
    this.accountService.resendConfCode(this.email)
      .subscribe(
        arg => {

          this.logger.info('done');
          this.snackBar.open($localize `Email sent`, $localize `OK`);
        },
        err => {
          this.snackBar.open( $localize `Error sending registration email`, $localize `RETRY`, {
            panelClass: ['error-snackbar']
          }).onAction().subscribe(() => {
            this.resendCode();
          });

        }
      );
  }


}
