//file to define generic types

export enum GraphGrouping {
    raw = 'raw',
    months = 'months',
    days = 'days',
    hours = 'hours'
}

export enum Timeframe {
    Hour = 'hour',
    Day = 'day',
    Week = 'week',
    Month = 'month',
    Year = 'year'
}

