import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { Prelaunch, PrelaunchAdapter } from "src/app/core/models/prelaunch.model";
import { HydraoApiService } from "src/app/core/services/core/hydrao-api.service";



@Injectable({
    providedIn: 'root'
})
export class PrelaunchService extends HydraoApiService<Prelaunch>{

    constructor(
        injector: Injector,
        private _prelaunchAdapter: PrelaunchAdapter) {
        super(injector);
    }

    /**
     * Return prelaunch
     */
    public check(): Observable<Prelaunch> {
        let url = '/prelaunch/';
        return this.get(url, this._prelaunchAdapter, false, true) as Observable<Prelaunch>;
    }

}
