import { Injectable } from '@angular/core';
import { Adapter } from 'src/app/core/adapter';
import { Model } from 'src/app/core/models/model';

export class Credentials implements Model {
   
    
    constructor(
        public email: string,
        public password: string,
        public refresh_token: string
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class CredentialsAdapter implements Adapter<Credentials> {

    adapt(item: any): Credentials {
        return new Credentials(
            item.email,
            item.password,
            item.refresh_token
        );
    }
}