import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { CookieService } from 'ngx-cookie-service';
import { JoyrideService } from 'ngx-joyride';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, delay, tap, map } from 'rxjs';
import { AlertDefinition } from 'src/app/core/models/alert-definition.model';
import { Alert, AlertDevice, Anomaly, AnomalyType, DeviceType } from 'src/app/core/models/alert.model';
import { Meter, MeterFeature, MeterType } from 'src/app/core/models/meter.model';
import { Place } from 'src/app/core/models/place.model';
import { MeterStats, PlaceStats, ShowerHeadStats, StatsElement } from 'src/app/core/models/placestats.model';
import { ShowerHead } from 'src/app/core/models/shower-head.model';
import { Threshold } from 'src/app/core/models/threshold.model';
import { OnBoardingSection, User } from 'src/app/core/models/user.model';
import { CacheService } from 'src/app/core/services/core/cache.service';
import { UserService } from 'src/app/core/services/user.service';
import { DateSelectorService } from 'src/app/shared/services/date-selector.service';
import { UtilsService } from 'src/app/shared/services/utils.service';


const LOG_TAG = '[TOUR]';

@Injectable({
  providedIn: 'root'
})
export class TourService {

  private static _TOUR_DELAY = 1000;


  public get fakeMeter() { this._initData(); return this._fakeMeter; }
  public get fakeSh() { this._initData(); return this._fakeSh; }
  public get fakeSh2() { this._initData(); return this._fakeSh2; }
  public get meterDataMonth() { this._initData(); return this._meterDataMonth; }
  public get shDataMonth() { this._initData(); return this._shDataMonth; }
  public get totalMeterVolMonth() { this._initData(); return this._totalMeterVolMonth; }
  public get totalShVolMonth() { this._initData(); return this._totalShVolMonth; }
  public get totalNbShowerMonth() { this._initData(); return this._totalNbShowerMonth; }
  public get meterDataLast30d() { this._initData(); return this._meterDataLast30d; }
  public get shDataLast30d() { this._initData(); return this._shDataLast30d; }
  public get fakePlaceStats() { this._initData(); return this._fakePlaceStats; }
  public get fakePlaces() { this._initData(); return this._fakePlaces; }
  public get fakeAlerts() { this._initData(); return this._fakeAlerts; }
  public get fakeAlertDefinitions() { this._initData(); return this._fakeAlertDefinitions; }


  private _user: User;
  private _prev: string = $localize`prev`;
  private _next: string = $localize`next`;
  private _done: string = $localize`done`;
  private _dataInitalized = false;


  private _fakeMeter: Meter;
  private _fakeSh: ShowerHead;
  private _fakeSh2: ShowerHead;
  private _meterDataMonth: [DateTime, number][];
  private _shDataMonth: [DateTime, number, number][];
  private _totalMeterVolMonth: number;
  private _totalShVolMonth: number;
  private _totalNbShowerMonth: number;
  private _meterDataLast30d: [DateTime, number][];
  private _shDataLast30d: [DateTime, number][];

  private _fakePlaceStats: PlaceStats[];
  private _fakePlaces: Place[];
  private _fakeAlerts: Alert[];
  private _fakeAlertDefinitions: AlertDefinition[];

  private get _isAdminModeEnabled(): boolean {
    return this._cookieService.check('admin_on_behalf');
  }

  private get _mobileMode(): boolean {
    return window.innerWidth <= 800;
  }


  constructor(
    private _joyride: JoyrideService,
    private _dateSvc: DateSelectorService,
    private _router: Router,
    private _logger: NGXLogger,
    private _utils: UtilsService,
    private _cacheSvc: CacheService,
    private _userSvc: UserService,
    private _location: Location,
    private _cookieService: CookieService) {

  }


  private _initData() {
    if (this._dataInitalized) return;
    this._computeDevicesData();
    this._computePlaceData();
    this._computeAlertData();
    this._dataInitalized = true;
  }



  public meterTourEnabled(): Observable<boolean> {
    if (this._isAdminModeEnabled) return of(false);
    if (this._mobileMode) return of(false);
    return this._getUser().pipe(map((u) => u.onboarding.indexOf(OnBoardingSection.Meter) >= 0))
  }


  public startMeterTour(): Observable<any> {
    return this.meterTourEnabled()
      .pipe(delay(TourService._TOUR_DELAY))
      .pipe(tap((enabled) => {
        if (enabled) {
          this._initData();
          this._joyride.startTour(
            {
              waitingTime: 300,
              steps: [
                'meterDashCustomize@meters',
                'meterDateButton@meters',
                'meterList@meters/devices',
                'meterListDate@meters/devices',
                'meterDetails@meters/devices',
                'meterStats@meters/012345678/stats',
                'selectDate@meters/012345678/stats',
                'meterDetailedUse@meters/012345678/details',
                'meterInfo@meters/012345678/infos',
                'meterJump@meters/012345678/infos',
                'meterAdd@meters/devices'
              ],
              customTexts: { next: this._next, prev: this._prev, done: this._done }
            }
          ).subscribe({
            next: async (step) => {
              // console.log(step);
              // if (step.name == 'selectDate') {
              //   this._dateSvc.openPanel();

              // } 
            },
            complete: () => {
              this._logger.info(LOG_TAG, 'tour finished');
              this._user.disableOnBoarding(OnBoardingSection.Meter);
              this._location.replaceState(`/meters/devices`);
              this._userSvc.saveUserData(this._user).subscribe(() => window.location.reload());
            }
          });
        }

      }));

  }

  public generalTourEnabled(): Observable<boolean> {
    if (this._isAdminModeEnabled) return of(false);
    if (this._mobileMode) return of(false);

    return this._getUser().pipe(map((u) => u.onboarding.indexOf(OnBoardingSection.General) >= 0))
  }


  public starGeneralTour(): Observable<any> {
    return this.generalTourEnabled()
      .pipe(delay(TourService._TOUR_DELAY))
      .pipe(tap((enabled) => {
        this._logger.info(LOG_TAG, 'start general tour');
        if (enabled) {
          this._initData();
          this._joyride.startTour(
            {
              waitingTime: 500,
              steps: [
                'firstStep',
                'menu',
                'settingsStep@/settings',
                'exportData@/settings',
                'menuHome@/dashboard',
                'dashArea@/dashboard',
                'dashRepartition@/dashboard',
                'dashAlerts@/dashboard',
                'dashActive@/dashboard',
                'continueTour@/dashboard'
              ],
              customTexts: { next: this._next, prev: this._prev, done: this._done }
            }
          ).subscribe({
            next: async (step) => {
              // console.log(step);
              // if (step.name == 'selectDate') {
              //   this._dateSvc.openPanel();

              // } 
            },
            complete: () => {
              this._logger.info(LOG_TAG, 'tour finished');
              this._user.disableOnBoarding(OnBoardingSection.General);
              this._userSvc.saveUserData(this._user).subscribe(() => window.location.reload());
            }
          });
        }

      }));


  }


  public placeTourEnabled(): Observable<boolean> {
    if (this._isAdminModeEnabled) return of(false);
    if (this._mobileMode) return of(false);

    return this._getUser().pipe(map((u) => u.onboarding.indexOf(OnBoardingSection.Places) >= 0))
  }

  public startPlaceTour(): Observable<any> {

    return this.placeTourEnabled()
      .pipe(delay(TourService._TOUR_DELAY))
      .pipe(tap((enabled) => {
        if (enabled) {
          this._initData();
          this._joyride.startTour(
            {
              steps: [
                'globalPlaceMap@places',
                'placeChangeDate@places',
                'placeMapInView@places',
                'mapSelectedPlace@places/1',
                'placeDetailsBtn@places/1',
                'placeDate@places/1/details',
                'placeActionButtons@places/1/details',
                'placeSh@places/1/details',
                'placeMeter@places/1/details',
                'placeSubplaces@places/1/details',
                'addPlace@places'
              ],
              customTexts: { next: this._next, prev: this._prev, done: this._done }
            }
          ).subscribe({
            next: async (step) => {
              // console.log(step);
              // if (step.name == 'selectDate') {
              //   this._dateSvc.openPanel();

              // } 
            },
            complete: () => {
              this._logger.info(LOG_TAG, 'tour finished');
              this._user.disableOnBoarding(OnBoardingSection.Places);
              this._userSvc.saveUserData(this._user).subscribe(() => window.location.reload());
            }
          });
        }

      }));
  }

  public alertTourEnabled(): Observable<boolean> {
    if (this._isAdminModeEnabled) return of(false);
    if (this._mobileMode) return of(false);

    return this._getUser().pipe(map((u) => u.onboarding.indexOf(OnBoardingSection.Alerts) >= 0))
  }

  public startAlertTour() {


    return this.alertTourEnabled()
      .pipe(delay(TourService._TOUR_DELAY))
      .pipe(tap((enabled) => {
        if (enabled) {
          this._initData();
          this._joyride.startTour(
            {
              steps: [
                'alertsList@alerts',
                'alertsMap@alerts',
                'alertSettings@alerts/settings',
                'createAlert@alerts/settings',
                'alertDefList@alerts/settings',
                'alertPhoneEdit@alerts/settings'
              ],
              customTexts: { next: this._next, prev: this._prev, done: this._done }
            }
          ).subscribe({
            next: async (step) => {
              // console.log(step);
              // if (step.name == 'selectDate') {
              //   this._dateSvc.openPanel();

              // } 
            },
            complete: () => {
              this._logger.info(LOG_TAG, 'tour finished');
              this._user.disableOnBoarding(OnBoardingSection.Alerts);
              this._userSvc.saveUserData(this._user).subscribe(() => window.location.reload());
            }
          });
        }

      }));



  }

  public shTourEnabled(): Observable<boolean> {
    if (this._isAdminModeEnabled) return of(false);
    if (this._mobileMode) return of(false);

    return this._getUser().pipe(map((u) => u.onboarding.indexOf(OnBoardingSection.ShowerHead) >= 0))
  }

  public startShTour() {


    return this.shTourEnabled()
      .pipe(delay(TourService._TOUR_DELAY))
      .pipe(tap((enabled) => {
        if (enabled) {
          this._initData();
          this._joyride.startTour(
            {
              waitingTime: 300,
              steps: [
                'shDashCustomize@shower-heads',
                'shDateButton@shower-heads',
                'shList@shower-heads/devices',
                'shListDate@shower-heads/devices',
                'shDetails@shower-heads/devices',
                'shStats@shower-heads/1/stats',
                'shDate@shower-heads/1/stats',
                'shInfo@shower-heads/1/infos',
                'shEditLabel@shower-heads/1/infos',
                'shThesholdButton@shower-heads/1/infos',
                'shJump@shower-heads/1/infos',
                'shAdd@shower-heads/devices',
              ],
              customTexts: { next: this._next, prev: this._prev, done: this._done }
            }
          ).subscribe({
            next: async (step) => {
              // console.log(step);
              // if (step.name == 'selectDate') {
              //   this._dateSvc.openPanel();

              // } 
            },
            complete: () => {
              this._logger.info(LOG_TAG, 'tour finished');
              this._user.disableOnBoarding(OnBoardingSection.ShowerHead);
              this._location.replaceState(`/shower-heads/devices`);

              this._userSvc.saveUserData(this._user).subscribe(() => window.location.reload());
            }
          });
        }

      }));

  }


  private _computeDevicesData() {
    this._fakeMeter = new Meter(
      '012345678',
      DateTime.local().minus({ month: 3 }),
      DateTime.local().minus({ minutes: 3 }),
      'Meter 1',
      'JA012345',
      DateTime.local(),
      null,
      null,
      [MeterFeature.hourly_signal, MeterFeature.raw_signal, MeterFeature.threshold_alerts, MeterFeature.period_alerts, MeterFeature.smart_alerts, MeterFeature.labelling],
      MeterType.drac_flowmeter,
      null,
      75);

    this._fakeSh = new ShowerHead(
      '1',
      DateTime.local().minus({ months: 3 }),
      DateTime.local().minus({ hours: 6 }),
      Threshold.DEFAULT_THRESHOLDS,
      null,
      'showerhead 1',
      '00_00_0000',
      'aloe',
      'LoRa',
      '123',
      '456',
      1
    );

    this._fakeSh2 = new ShowerHead(
      '2',
      DateTime.local().minus({ months: 12 }),
      DateTime.local().minus({ months: 6 }),
      Threshold.DEFAULT_THRESHOLDS,
      null,
      'showerhead 2',
      '00_00_0001',
      'Cereus',
      'BLE',
      '123',
      '456',
      1
    )
    this._meterDataMonth = [];
    this._meterDataLast30d = [];
    this._shDataMonth = [];
    this._shDataLast30d = [];

    let beginOfMonth = DateTime.local().set({ day: 0, hour: 0, minute: 0, second: 0, millisecond: 0 })
    this._totalMeterVolMonth = 0;
    this._totalShVolMonth = 0;
    this._totalNbShowerMonth = 0;
    for (let date = DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }); date > DateTime.local().minus({ days: 30 }).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }); date = date.minus({ day: 1 })) {
      let vol = Math.round(Math.random() * 80) + 40;
      let shVol = Math.round(Math.random() * 60) + 30;
      let nbSh = Math.round(Math.random() * 2) + 1;

      if (date >= beginOfMonth) {
        this._totalMeterVolMonth += vol;
        this._totalShVolMonth += shVol;
        this._totalNbShowerMonth += nbSh;
        this._meterDataMonth.push([date, vol]);
        this._meterDataLast30d.push([date, vol]);
        this._shDataMonth.push([date, shVol, nbSh]);
        this._shDataLast30d.push([date, shVol]);
      } else {
        this._meterDataLast30d.push([date, vol]);
        this._shDataLast30d.push([date, shVol]);
      }


      //graph.push(new MeterGraphElement(date.toJSDate(), 6.0, 0, vol));

    }
  }

  private _computeAlertData() {
    this._fakeAlerts = [];
    this._fakeAlertDefinitions = [];
    this._fakeAlertDefinitions.push(new AlertDefinition(1, null, null, null, null, AnomalyType.meter_offline, null, false, false, true));
    this._fakeAlertDefinitions.push(new AlertDefinition(2, null, null, null, null, AnomalyType.gateway_offline, null, false, false, true));
    this._fakeAlertDefinitions.push(new AlertDefinition(3, null, null, null, null, AnomalyType.low_capacitor, null, false, false, true));
    this._fakeAlertDefinitions.push(new AlertDefinition(4, this._fakeMeter.device_uuid, this._fakeMeter, null, null, AnomalyType.volume, 50, false, false, true));
    this._fakeAlertDefinitions.push(new AlertDefinition(5, null, null, this._fakePlaces[0].id, this._fakePlaces[0], AnomalyType.flow_profile, 50, false, false, true));
    this._fakeAlertDefinitions.push(new AlertDefinition(6, null, null, null, null, AnomalyType.daily_profile, 75, false, false, false));

    this._fakeAlerts.push(new Alert(
      () => this._cacheSvc.inMemoryData.get('user') as User,
      this._utils,
      new Anomaly(
        1,
        AnomalyType.gateway_offline,
        true,
        DateTime.local().minus({ hours: 2.3 }),
        DateTime.local().minus({ hours: 1.1 }),
        DateTime.local().minus({ hours: 2.3 }),
        DateTime.local().minus({ hours: 1.1 }),
        600,
        5.3,
        5,
        7,
      ),
      this._fakeAlertDefinitions[2],
      new AlertDevice(this._fakeMeter.device_uuid, DeviceType.meter, this._fakeMeter.label, this._fakeMeter.serial),
      this._fakePlaces[0]

    ));
    this._fakeAlerts.push(new Alert(
      () => this._cacheSvc.inMemoryData.get('user') as User,
      this._utils,
      new Anomaly(
        1,
        AnomalyType.volume,
        true,
        DateTime.local().minus({ hours: 2.3 }),
        DateTime.local().minus({ hours: 1.1 }),
        DateTime.local().minus({ hours: 2.3 }),
        DateTime.local().minus({ hours: 1.1 }),
        600,
        5.3,
        5,
        7,
      ),
      this._fakeAlertDefinitions[3],
      new AlertDevice(this._fakeMeter.device_uuid, DeviceType.meter, this._fakeMeter.label, this._fakeMeter.serial),
      this._fakePlaces[0]


    ));
    this._fakeAlerts.push(new Alert(
      () => this._cacheSvc.inMemoryData.get('user') as User,
      this._utils,
      new Anomaly(
        1,
        AnomalyType.flow_profile,
        true,
        DateTime.local().minus({ hours: 2.3 }),
        DateTime.local().minus({ hours: 1.1 }),
        DateTime.local().minus({ hours: 2.3 }),
        DateTime.local().minus({ hours: 1.1 }),
        6,
        5.3,
        5,
        7,
      ),
      this._fakeAlertDefinitions[3],
      new AlertDevice(this._fakeMeter.device_uuid, DeviceType.meter, this._fakeMeter.label, this._fakeMeter.serial),
      this._fakePlaces[0]
    ));

    this._fakePlaces[0].anomalies = this._fakeAlerts.length;


  }

  private _computePlaceData() {

    let getPlaceStats = (placeId: number) => {
      let meterStatElt = new StatsElement(this._utils, Math.round(Math.random() * 100), Math.random() * 2 - 1, Math.random() * 8 + 2, Math.random() * 2 - 1, Math.random() * 40 + 15, Math.random() * 2 - 1);
      let shStatElt = new StatsElement(this._utils, Math.round(Math.random() * 100), Math.random() * 2 - 1, Math.random() * 2 + 5, Math.random() * 2 - 1, Math.random() * 20 + 30, Math.random() * 2 - 1);
      let statElt = new StatsElement(this._utils, meterStatElt.volume + shStatElt.volume, 0, 0, 0, 0, 0);
      shStatElt.updateValuesWithUnits().subscribe();
      meterStatElt.updateValuesWithUnits().subscribe();
      statElt.updateValuesWithUnits().subscribe();
      return new PlaceStats(statElt, new ShowerHeadStats(1, shStatElt), new MeterStats(1, meterStatElt), placeId);
    }

    this._fakePlaces = [];
    this._fakePlaceStats = [];

    this._fakePlaces.push(new Place(
      1,
      'Hôtel de paris',
      'Hôtel de paris',
      'France - Paris',
      48.86690178,
      2.31496081,
      1,
      null,
      [
        new Place(12, 'floor 1', 'Hôtel de paris/floor 1', '', null, null, 2, 1, [
          new Place(121, 'room 1', 'Hôtel de paris/floor 1/room 1', '', null, null, 2, 1, [], 0),
          new Place(122, 'room 2', 'Hôtel de paris/floor 1/room 2', '', null, null, 2, 1, [], 0)
        ], 0),
        new Place(13, 'floor 2', 'Hôtel de paris/floor 2', '', null, null, 2, 1, [], 0)],
      0
    ));

    this._fakePlaceStats.push(this._fakePlaces[0].stats = getPlaceStats(1));


    // this.fakePlaces.push(new Place(
    //   1, 'Hôtel de paris',
    //   'France - Paris',
    //   48.86690178,
    //   2.31496081,
    //   1,
    //   null,
    //   null,
    //   0
    // ));

    // this.fakePlaceStats.push(this.fakePlaces[1].stats = getPlaceStats());

    this._fakePlaces.push(new Place(
      2,
      'Hôtel de Grenoble',
      'Hôtel de Grenoble',
      'Place de la gare',
      45.18616545,
      5.7336987,
      1,
      null,
      null,
      0
    ));
    this._fakePlaceStats.push(this._fakePlaces[1].stats = getPlaceStats(2));
    this._fakePlaces.push(new Place(
      3,
      'Hôtel Canebière',
      'Hôtel Canebière',
      'France - Marseille',
      43.29593616,
      5.37673245,
      1,
      null,
      null,
      0
    ));
    this._fakePlaceStats.push(this._fakePlaces[2].stats = getPlaceStats(3));
  }


  private _getUser(): Observable<User> {
    this._user = this._cacheSvc.inMemoryData.get('user') as User;
    if (!this._user) {
      return this._userSvc.getUserData().pipe(tap((u) => this._user = u));
    } else {
      return of(this._user);
    }


  }

}
