import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime, Settings } from 'luxon';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom, Subscription } from 'rxjs';
import { Place } from 'src/app/core/models/place.model';
import { PlaceService } from 'src/app/core/services/place.service';
import { GraphGrouping, Timeframe } from 'src/app/shared/types/hydrao-types';

const LOG_TAG = '[DATE_SELECTOR]';

class DateChanges {
  date: DateTime;
  timeframe: Timeframe;
  placeId: number;
  public hasChange(data: DateSelectorService) {
    return this.hasDateChange(data) || this.hasPlaceChange(data);
  }
  public hasDateChange(data: DateSelectorService) {
    return (data.date && !data.date.equals(this.date)) || data.timeframe != this.timeframe;
  }

  public hasPlaceChange(data: DateSelectorService) {
    return data.placeId !== this.placeId;
  }
}

export enum Change {
  Place,
  Date
}

@Injectable({
  providedIn: 'root'
})
export class DateSelectorService {


  public panelOpenRequest = new EventEmitter<boolean>()
  public panelOpened: boolean = false;

  public dateChange: EventEmitter<Change[]> = new EventEmitter<Change[]>();

  public withPlaces: boolean = true;
  public disableDaySelection: boolean = false;
  public withChildPlaces: boolean = true;

  public get date(): DateTime { return this._date }
  public get timeframe(): Timeframe { return this._timeframe }
  public get graphGrouping(): GraphGrouping {
    switch (this._timeframe) {
      case Timeframe.Hour:
        return GraphGrouping.raw;
      case Timeframe.Day:
        return GraphGrouping.hours;
      case Timeframe.Week:
      case Timeframe.Month:
        return GraphGrouping.days
      case Timeframe.Year:
        return GraphGrouping.months;
    }
  }
  public get from(): DateTime {
    return this._date.toLocal().startOf(this._timeframe);
  }
  public get to(): DateTime {
    return this._date.toLocal().endOf(this._timeframe);
  }

  public getEltEndDate(date: DateTime) {
    let duration;
    switch (this.graphGrouping) {
      case GraphGrouping.days:
        duration = 'day';
        break;
      case GraphGrouping.hours:
        duration = 'hour';
        break;
      case GraphGrouping.months:
        duration = 'month';
        break;
      default:
        return null;
    }

    return date.endOf(duration);
  }

  public get placeId() { return this._placeId }
  public get placeName() { return this._placeName }


  public get dateLabel() {
    return DateSelectorService.computeDateLabel(this.date, this.timeframe);
  }

  public static computeDateLabel(date: DateTime, timeframe: Timeframe): string {
    switch (timeframe) {
      case Timeframe.Hour:
        return $localize`${date.toLocal().toFormat('DD')}:date: at ${date.toLocal().toFormat('t')}:hour:`;
      case Timeframe.Day:
        return $localize`${date.toLocal().toFormat('DD')}:date:`;
      case Timeframe.Week:
        return $localize`the week of ${date.toFormat('DD')}:date:`;
      case Timeframe.Month:
        return $localize`${date.toLocal().toFormat('MMM yyyy')}:date:`;
      case Timeframe.Year:
        return $localize`year ${date.toLocal().toFormat('yyyy')}:date:`;
    }
  }

  private _date: DateTime;
  private _timeframe: Timeframe;
  private _placeId: number;
  private _placeName: string;
  private _placeSub: Subscription;


  constructor(
    private logger: NGXLogger,
    private router: Router,
    private route: ActivatedRoute,
    private placeSvc: PlaceService,
    private cookieService: CookieService
  ) {
    this.route.queryParams.subscribe(async (param) => {


      let changes = new DateChanges();
      if (param.date) {
        changes.date = DateTime.fromISO(param.date)
      } else {
        changes.date = this.date ? this.date : DateTime.local().toUTC();
      }
      if (param.tf) {
        changes.timeframe = param.tf;
      } else {
        changes.timeframe = Timeframe.Month;
      }

      if (param.place && !isNaN(+param.place)) {
        changes.placeId = parseInt(param.place);
      } else {
        changes.placeId = null;
      }


      this.panelOpened = (param.panel == 'opened');
      if (this.panelOpened) {
        this.cookieService.set('date_selector_pulse_ack', 'ok');
      }

      if (!changes.placeId) {
        this._placeName = $localize`all places`;
      } else {
        this.placeSvc.computePlaceLabelFromId(changes.placeId)
          .subscribe((name) => this._placeName = name);
      }


      if (changes.hasChange(this)) {
        let changeList: Change[] = [];
        if (changes.hasDateChange(this)) changeList.push(Change.Date);
        if (changes.hasPlaceChange(this)) changeList.push(Change.Place);
        this.commitChanges(changes);

        this.dateChange.emit(changeList);
      }
    });

  }

  private commitChanges(changes: DateChanges) {
    this._date = changes.date;
    this.logger.info(LOG_TAG, `date is ${this._date ? this._date.toISO() : 'NULL'}`);

    this._placeId = changes.placeId;
    this._timeframe = changes.timeframe;
  }



  public togglePanel() {
    this.panelOpened = !this.panelOpened;
    this.router.navigate([], {
      replaceUrl: true,
      queryParams: {
        panel: this.panelOpened ? 'opened' : 'closed'
      },
      queryParamsHandling: 'merge'

    });
    this.panelOpenRequest.emit(this.panelOpened);
  }

  public openPanel() {
    this.panelOpenRequest.emit(true);
    this.panelOpened = true;
    this.router.navigate([], {
      replaceUrl: true,
      queryParams: {
        panel: 'opened'
      },
      queryParamsHandling: 'merge'

    });
  }


  public closePanel() {
    this.panelOpenRequest.emit(false);
    this.panelOpened = false;
    this.router.navigate([], {
      replaceUrl: true,
      queryParams: {
        panel: 'closed'
      }, queryParamsHandling: 'merge'

    });
  }


  public async changeDate(date: DateTime, tf: Timeframe, placeid: number): Promise<void> {
    this.logger.info(LOG_TAG, `date change : date ${date} timeframe ${tf} place ${placeid}`);

    // this._date = date;
    // this._timeframe = tf;
    // this._placeId = placeid > 0 ? placeid : null;


    //this.refreshData();
    await this.router.navigate([], {
      //relativeTo: this.route,
      replaceUrl: true,
      queryParams: {
        date: date.toUTC().toISO(),
        tf: tf,
        place: placeid > 0 ? placeid : null,
        panel: this.panelOpened ? 'opened' : 'closed'
      },
      queryParamsHandling: 'merge'
    });


  }

}
