import { Injectable, Injector } from "@angular/core";
import { Observable, of } from "rxjs";
import { SearchResult, SearchResultAdapter } from "src/app/core/models/search-result.model";
import { HydraoApiService, HydraoApiServiceBase } from "src/app/core/services/core/hydrao-api.service";


@Injectable({
    providedIn: 'root'
})
export class LogService extends HydraoApiServiceBase {

    constructor(
        injector: Injector) {
        super(injector);
    }

    /**
     * Return places
     */
    public sendLogs(id: string, content: string): Observable<void> {
        
        let url = `/logfile/${id}`;

        return this.rawPut(url, content, true);
    }

}
