import { Injectable } from "@angular/core";
import { Adapter } from "src/app/core/adapter";
import { Alert, AnomalyType } from "src/app/core/models/alert.model";
import { Meter, MeterAdapter } from "src/app/core/models/meter.model";
import { Model } from "src/app/core/models/model";
import { Place, PlaceAdapter } from "src/app/core/models/place.model";


export enum AlertCategory {
    smart = 'smart',
    threshold = 'threshold',
    dysfunction = 'dysfunction'
}


const labels: Map<AnomalyType, string> = new Map<AnomalyType, string>([
    [AnomalyType.daily_profile, $localize`day consumption`],
    [AnomalyType.duration, $localize`duration`],
    [AnomalyType.flow_profile, $localize`flow`],
    [AnomalyType.hourly_profile, $localize`hour consumption`],
    [AnomalyType.volume, $localize`volume`],
    [AnomalyType.daily_volume, $localize`24h volume`],
    [AnomalyType.monthly_volume, $localize`30d volume`],
    [AnomalyType.meter_offline, $localize`Meter offline`],
    [AnomalyType.gateway_offline, $localize`Gateway offline`],
    [AnomalyType.low_capacitor, $localize`Low energy on meter`],

  ]);

export class AlertDefinition implements Model {


    public category: AlertCategory;
    public targetSet = false;

    public get valueValid() {
        return (this.minValue === null || this.value >= this.minValue) && (this.maxValue === null  || this.value <= this.maxValue);
    };

    public get typeLabel() {
        return labels.get(this.type);
    }


    public get minValue(): number {
        switch (this.type) {
            case AnomalyType.volume:
                return 40;
            case AnomalyType.daily_volume:
                return 40;
            case AnomalyType.monthly_volume:
                return 1000;
            case AnomalyType.duration:
                return 900; //15 min
            default:
                return null;
        }
    }

    public get maxValue(): number {
        switch (this.type) {
            case AnomalyType.volume:
                return 500;
            case AnomalyType.daily_volume:
                return 60000;
            case AnomalyType.monthly_volume:
                return 1800000;
            case AnomalyType.duration:
                return 14400; //4h
            default:
                return null;
        }
    }


    private _type: AnomalyType;


    public get type(): AnomalyType {
        return this._type;
    }
    public set type(value: AnomalyType) {

        if (value == AnomalyType.daily_profile || value == AnomalyType.flow_profile || value == AnomalyType.hourly_profile) {
            this.category = AlertCategory.smart;
        } else if (value == AnomalyType.duration || value == AnomalyType.volume || value == AnomalyType.monthly_volume || value == AnomalyType.daily_volume) {
            this.category = AlertCategory.threshold;
        } else if (value == AnomalyType.low_capacitor || value == AnomalyType.meter_offline || value == AnomalyType.gateway_offline) {
            this.category = AlertCategory.dysfunction;
        } else {
            this.category = null;
        }

        this._type = value;
    }

    // public get isSmartAlert() {
    //     return this.type == AnomalyType.daily_profile || this.type == AnomalyType.flow_profile || this.type == AnomalyType.hourly_profile;
    // }

    // public get isThresholdAlert() {
    //     return this.type == AnomalyType.duration || this.type == AnomalyType.volume;
    // }


    public getFullLabel() {
        let label: string = '';

        if (this.meter) {
            label += $localize`on meter ${this.meter.universalLabel}`;
        } else if (this.place) {
            label += $localize`on place ${this.place.label};`
        } else {
            label += $localize`on all meters`;
        }
        return label;
    }

    constructor(
        public id: number,
        public target_meter: string,
        public meter: Meter,
        public target_place: number,
        public place: Place,
        type: AnomalyType,
        public value: number,
        public sms_notif_enabled: boolean,
        public email_notif_enabled: boolean,
        public enabled: boolean
    ) {
        this.type = type;
    }

    public toJSON(): AlertDefinition {
        return {
            id: this.id,
            target_meter: this.target_meter,
            target_place: this.target_place,
            type: this.type,
            value: this.value,
            sms_notif_enabled: this.sms_notif_enabled,
            email_notif_enabled: this.email_notif_enabled,
            enabled: this.enabled

        } as AlertDefinition;
    }

    public clone(): AlertDefinition {
        return new AlertDefinition(
            this.id,
            this.target_meter,
            this.meter,
            this.target_place,
            this.place,
            this.type,
            this.value,
            this.sms_notif_enabled,
            this.email_notif_enabled,
            this.enabled);
    }

    public static get VoidAlertDefinition() {
        return new AlertDefinition(null, null, null, null, null, null, null, false, false, false);
    }



}




@Injectable({
    providedIn: 'root'
})
export class AlertDefinitionAdapter implements Adapter<AlertDefinition> {

    constructor(private _meterAdapter: MeterAdapter, private _placeAdapter: PlaceAdapter) { }

    public adapt(item: any): AlertDefinition {
        return new AlertDefinition(
            item.id,
            item.target_meter,
            item.meter ? this._meterAdapter.adapt(item.meter) : null,
            item.target_place,
            item.place ? this._placeAdapter.adapt(item.place) : null,
            item.type,
            item.value,
            item.sms_notif_enabled,
            item.email_notif_enabled,
            item.enabled
        );
    }
}
