import { Injectable } from '@angular/core';
import { Adapter } from 'src/app/core/adapter';
import { Model } from 'src/app/core/models/model';

export class HttpError implements Model {
    constructor(
        public http_status: number,
        public error_code: string,
        public error_message: string
    ) {
        console.log(`construct with ${error_code}`);
     }

    get message(): string {
        switch (this.error_code) {
            case ErrorCode.AuthFailed:
                return $localize`Authentication failed, please try again`;
            case ErrorCode.SessionExpired:
                return $localize`Session expired, please log in again`;
            case ErrorCode.Unexpected:
                return $localize`Unexpected error`;
            case ErrorCode.AccountDisabled:
                return $localize`Your account disabled.`;
            default:
                return this.error_message;
        }
    }

}

export enum ErrorCode {
    AuthFailed = 'AuthFailed',
    SessionExpired = 'SessionExpired',
    Unexpected = 'Unexpected',
    AccountDisabled = 'AccountDisabled'
};

@Injectable({
    providedIn: 'root'
})
export class HttpErrorAdapter implements Adapter<HttpError> {

    adapt(item: any): HttpError {
        if (item.message == 'session_expired') {
            return new HttpError(
                null,
                ErrorCode.SessionExpired,
                null
            );
        } else {
            return new HttpError(
                item.status,
                item.error ? item.error.error : ErrorCode.Unexpected,
                item.error ? item.error.message : null
            );
        }

    }
}