import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CacheService } from 'src/app/core/services/core/cache.service';

@Component({
  selector: 'hydrao-admin-bar',
  templateUrl: './admin-bar.component.html',
  styleUrls: ['./admin-bar.component.scss']
})
export class AdminBarComponent {

  constructor(private _cookieService: CookieService, private _cacheService: CacheService) { }

  public get isAdminModeEnabled():boolean {
    return this._cookieService.check('admin_on_behalf');
  }

  public exitAdminMode() {
    document.cookie = 'admin_on_behalf=;Max-Age=0;domain=hydrao.com;path=/';
    document.cookie = 'admin_token=;Max-Age=0;domain=hydrao.com;path=/';
    //this._cookieService.delete('admin_token', '/');
    //this._cookieService.delete('admin_on_behalf', '/');
    localStorage.removeItem('token');
    this._cacheService.clearAll().subscribe(()=> {
      window.location.href="/";
      window.location.reload();
    });
    
  }

}
