import { Injectable, Injector } from '@angular/core';
import { throwError, Subject, Observable } from 'rxjs';
import { map, tap, catchError, first, switchMap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { HydraoApiService } from 'src/app/core/services/core/hydrao-api.service';
import { Credentials } from 'src/app/core/models/credentials.model';
import { Token, TokenAdapter } from 'src/app/core/models/token.model';
import { HttpError } from 'src/app/core/models/error.model';
import { HydraoLogMonitor } from 'src/app/core/log-monitor';




@Injectable({
  providedIn: 'root'
})
export class AuthService extends HydraoApiService<Credentials> {

  public get adminBehalf() {
    return this.cookieService.get('admin_on_behalf');
  }

  public get token() {
    if (this.adminBehalf) {
      return this.cookieService.get('admin_token');
    } else {
      return localStorage.getItem('token');
    }

  }
  private static get REFRESH_TOKEN() { return localStorage.getItem('refresh_token'); }

  public get needAuth(): boolean {
    return (!AuthService.REFRESH_TOKEN || this.adminBehalf) && !this.token ? true : this._needAuth;
  }

  public needAuthEvent = new Subject<void>();

  private _needAuth: boolean;


  constructor(
    injector: Injector,
    private adapter: TokenAdapter,
    private cookieService: CookieService,
    private _logMonitor: HydraoLogMonitor
  ) {

    super(injector);
  }




  /**
  * Log the user, and save the access token and the refresh token
  * @param login The login
  * @param pass The password
  * @param remember Remember me
  */
  public login(login: string, pass: string, remember: boolean) {
    document.cookie = 'admin_on_behalf=;Max-Age=0;domain=hydrao.com;path=/';
    document.cookie = 'admin_token=;Max-Age=0;domain=hydrao.com;path=/';
    return this.cacheService.clearAll().pipe(switchMap(() => {
      return this.post('/sessions', new Credentials(login, pass, null), null).pipe(
        map((data: any) => this.adapter.adapt(data)),
        tap((token: Token) => {
          this.logger.info('store token');
          localStorage.setItem('login', login);
          token.storeToken();
          this._needAuth = false;
          if (remember) {
            this.logger.info('store refresh token as remember me is checked');
            token.storeRefresToken();
          }
        })
      );
    }));
  }

  public async logout() {
    await this._logMonitor.flushAndClean();

    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('login');
    this.cookieService.delete('date_selector_pulse_ack');
    document.cookie = 'admin_on_behalf=;Max-Age=0;domain=hydrao.com;path=/';
    document.cookie = 'admin_token=;Max-Age=0;domain=hydrao.com;path=/';

    this.cacheService.clearAll().pipe(first()).subscribe(() => {
      this._needAuth = true;
      this.needAuthEvent.next();
    });
  }

  public refreshConnection(): Observable<any> {
    this.logger.info('refresh connection');
    if (this.adminBehalf) {
      this.logger.info('need auth in admin mode, clearing all cache and cookies');
      return this.cacheService.clearAll()
        .pipe(
          first(),
          tap(() => {
            document.cookie = 'admin_on_behalf=;Max-Age=0;domain=hydrao.com;path=/';
            document.cookie = 'admin_token=;Max-Age=0;domain=hydrao.com;path=/';
            this.logger.info('navigating to home page after clear. ');
            location.reload();
          }),
          switchMap(() => {
            throw new Error('exit_admin');
          })
        );
    } else if (!AuthService.REFRESH_TOKEN) {
      this.logger.info('no refresh token, need user to auth again');
      return this.cacheService.clearAll()
        .pipe(
          first(),
          tap(() => {
            document.cookie = 'admin_on_behalf=;Max-Age=0;domain=hydrao.com;path=/';
            document.cookie = 'admin_token=;Max-Age=0;domain=hydrao.com;path=/';
            this._needAuth = true;
            this.needAuthEvent.next();
          }),
          switchMap(() => {
            throw new Error('session_expired');
          })
        );
      //return Promise.reject('no refresh token');
    } else {
      this.logger.info('try to refresh session with refresh token');
      return this.post('/sessions', new Credentials(null, null, AuthService.REFRESH_TOKEN), null)
        .pipe(
          catchError((err: HttpError) => {
            if (err.http_status == 401) {
              this._needAuth = true;
              this.needAuthEvent.next();
            }
            this.logger.info(`Error trying to refresh session, going to login screen : ${JSON.stringify(err)}`)
            return throwError(() => new Error('session_expired'));
          }),
          map((data: any) => this.adapter.adapt(data)),
          tap((token: Token) => {
            this.logger.info('store token');
            token.storeToken();
            this._needAuth = false;
            this._logMonitor.startLogFlushing();
          })
        );
    }

  }
}
