import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterPageComponent } from './screens/register-page/register-page.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { ConfirmAccountComponent } from './screens/confirm-account/confirm-account.component';
import { RegisterConfirmationComponent } from './screens/register-confirmation/register-confirmation.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    RegisterPageComponent,
    ConfirmAccountComponent,
    RegisterConfirmationComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatCardModule,
    RouterModule
  ]
})
export class RegisterModule { }
