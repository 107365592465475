import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom } from 'rxjs';
import { HttpError, ErrorCode } from 'src/app/core/models/error.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: UntypedFormGroup;
  public loginFailed: boolean = false;

  public loginLoading: boolean = false;

  constructor(
    private logger: NGXLogger,
    private cdRef: ChangeDetectorRef,
    private authService: AuthService,
    private userService: UserService,
    private router: Router) { }

  public hasError(controlName: string, errorName: string) {
    return this.loginForm.controls[controlName].hasError(errorName);
  }

  public login(loginFormValue) {
    if (this.loginForm.valid) {
      this.logger.debug(`login with ${loginFormValue.email}`);

      this.loginLoading = true;
      this.authService.login(loginFormValue.email, loginFormValue.pass, loginFormValue.remember)
        .subscribe({
          next: (res) => {
            this.loginFailed = false;
            localStorage.setItem('login', loginFormValue.email);
            this.logger.debug(`response : ${JSON.stringify(res)}`);
            this.userService.getUserData().subscribe((user) => {
              if (!user.locale) {
                let browserLocale = navigator.language || (navigator as any).userLanguage;
                if (browserLocale == 'fr') {
                  this.logger.debug('browser locale seems to be fr');
                  user.locale = 'fr';
                } else {
                  this.logger.debug('brower local is not fr, set it to en');
                  user.locale = 'en';
                }
                this.userService.saveUserData(user).subscribe(() => {
                  this.loginLoading = false;
                  this.router.navigate(['dashboard'], { replaceUrl: true });
                });

              } else {
                this.loginLoading = false;
                this.router.navigate(['dashboard'], { replaceUrl: true });
              }
              
            });

          }, error: (err) => {
            this.loginLoading = false;
            if (err instanceof HttpError) {
              this.logger.warn(`error logging in : ${err.error_code} `);
              if (err.error_code == ErrorCode.AuthFailed) {
                this.loginFailed = true;
                this.cdRef.detectChanges();
              } else if (err.error_code == ErrorCode.AccountDisabled) {
                this.router.navigate(['verify-account']);
              }
            }
          }
        });






    }
  }

  public goToRegister() {
    this.router.navigate(['/register']);
  }

  public goToForgotPwd() {
    this.router.navigate(['/forgot-password']);
  }

  ngOnInit() {
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      pass: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      remember: new UntypedFormControl('true', [])
    });
  }
}
