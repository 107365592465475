import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { LoginPageComponent } from 'src/app/login/screens/login-page/login-page.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { RegisterPageComponent } from 'src/app/register/screens/register-page/register-page.component';
import { ConfirmAccountComponent } from 'src/app/register/screens/confirm-account/confirm-account.component';
import { RegisterConfirmationComponent } from 'src/app/register/screens/register-confirmation/register-confirmation.component';
import { CacheService } from 'src/app/core/services/core/cache.service';
import { first } from 'rxjs';
import { OnboardingComponent } from 'src/app/onboarding/screens/onboarding/onboarding.component';
import { AuthGuard } from 'src/app/core/auth-guard';
import { ForgotPasswordComponent } from 'src/app/login/screens/forgot-password/forgot-password.component';
import { ForgotPasswordConfirmationComponent } from 'src/app/login/screens/forgot-password-confirmation/forgot-password-confirmation.component';
import { TimezoneFirstSelectComponent } from 'src/app/onboarding/screens/timezone-first-select/timezone-first-select.component';
import { PrelaunchService } from 'src/app/core/services/prelaunch.service';
import { Prelaunch } from 'src/app/core/models/prelaunch.model';
import { MaintenanceModeComponent } from 'src/app/login/screens/maintenance-mode/maintenance-mode.component';



const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    loadChildren: async () => (await import('./dashboard/dashboard.module')).DashboardModule
  },
  {
    path: 'alerts',
    loadChildren: async () => (await import('./alert/alert.module')).AlertModule
  },
  {
    path: 'places',
    loadChildren: async () => (await import('./places/places.module')).PlacesModule
  },
  {
    path: 'shower-heads',
    loadChildren: async () => (await import('./shower-head/shower-head.module')).ShowerHeadModule
  },
  {
    path: 'meters',
    loadChildren: async () => (await import('./meter/meter.module')).MeterModule
  },
  {
    path: 'gateways',
    loadChildren: async () => (await import('./gateway/gateway.module')).GatewayModule
  },
  {
    path: 'accounts',
    loadChildren: async () => (await import('./child-account/child-account.module')).ChildAccountModule
  },
  {
    path: 'settings',
    loadChildren: async () => (await import('./settings/settings.module')).SettingsModule
  },
  {
    path: 'custom',
    loadChildren: async () => (await import('./custom-pages/custom-pages-index/custom-pages-index.module')).CustomPagesIndexModule
  },
  {
    path: 'hkust',
    loadChildren: async () => (await import('./custom-pages/hkust/hkust.module')).HkustModule
  },
  { path: 'login', component: LoginPageComponent },
  { path: 'register', component: RegisterPageComponent },
  { path: 'register/:emailBase64', component: RegisterPageComponent },
  { path: 'register-conf', component: RegisterConfirmationComponent },
  { path: 'verify-account', component: ConfirmAccountComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'forgot-password-conf/:emailBase64', component: ForgotPasswordConfirmationComponent },
  { path: 'onboarding', component: OnboardingComponent, canActivate: [AuthGuard], },
  { path: 'onboarding/timezone', component: TimezoneFirstSelectComponent, canActivate: [AuthGuard], },
  { path: 'maintenance', component: MaintenanceModeComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    router: Router,
    authService: AuthService,
    cacheService: CacheService,
    prelaunchService: PrelaunchService) {
    // if (authService.needAuth) {
    //   cacheService.clearAll().pipe(first()).subscribe(() => router.navigate(['login'], { replaceUrl: true }));

    // }

    prelaunchService.check().subscribe((prelaunch: Prelaunch) =>{
      if(prelaunch.maintenance) {
        router.navigate(['maintenance'], { replaceUrl: true });
      }
    });

    authService.needAuthEvent.subscribe({
      next: () => {
        cacheService.clearAll().pipe(first()).subscribe(() => { router.navigate(['login'], { replaceUrl: true }); });
      }
    });
  }

}
