<mat-card appearance="outlined" *ngIf="!loginLoading" class="mat-elevation-z0">
    <form  [formGroup]="loginForm" autocomplete="off" novalidate (ngSubmit)="login(loginForm.value)" fxLayout="column wrap"
        fxLayoutAlign="center center" fxLayoutGap="10px">
        <mat-form-field>
            <input required matInput i18n-placeholder placeholder="email" type="email" formControlName="email" id="email"
                autofocus>
            <mat-error *ngIf="hasError('email', 'required')" i18n>Email is required</mat-error>
            <mat-error *ngIf="hasError('email', 'email')" i18n>Please enter a valid email</mat-error>
        </mat-form-field>
        <mat-form-field>
            <input required matInput i18n-placeholder placeholder="password" type="password" formControlName="pass" id="pass">
            <mat-error *ngIf="hasError('pass', 'required')" i18n>Password is required</mat-error>
            <mat-error *ngIf="hasError('pass', 'minlength')" i18n>Password length is at least 8 characters</mat-error>
        </mat-form-field>
        <mat-checkbox formControlName="remember" id="remember" color="primary" i18n>Remember me</mat-checkbox>

        <mat-error *ngIf="loginFailed" i18n>Login failed, please verify your email and password</mat-error>
        <div class="buttons">
            <button mat-button color="primary"  (click)="goToForgotPwd()" type="button" i18n>forgot password ?</button>
            <button mat-flat-button color="primary" [disabled]="!loginForm.valid || loginLoading" i18n>login</button>
        </div>
        <div class="register-phrase" i18n>If you do not have an accout, you can <a (click)="goToRegister()">register</a></div>
    </form>
</mat-card>
<mat-progress-spinner  *ngIf="loginLoading" color="primary" mode="indeterminate" diameter="30" strokeWidth="2"></mat-progress-spinner>