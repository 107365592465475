import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { GraphD3Component } from 'src/app/shared/components/graph/graph-d3/graph-d3.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MetricsCardComponent } from 'src/app/shared/components/metrics-card/metrics-card.component';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { AdminBarComponent } from 'src/app/shared/components/admin-bar/admin-bar.component';
import { BarSkeletonComponent } from 'src/app/shared/components/bar-skeleton/bar-skeleton.component';
import { BubbleMapComponent } from 'src/app/shared/components/bubble-map/bubble-map.component';
import { DateBlocComponent } from 'src/app/shared/components/date-bloc/date-bloc.component';
import { DateButtonComponent } from 'src/app/shared/components/date-selector/date-button/date-button.component';
import { DatePanelComponent } from 'src/app/shared/components/date-selector/date-panel/date-panel.component';
import { DatePickerComponent } from 'src/app/shared/components/date-selector/date-picker/date-picker.component';
import { DayPickerComponent } from 'src/app/shared/components/date-selector/date-picker/day-picker/day-picker.component';
import { HourPickerComponent } from 'src/app/shared/components/date-selector/date-picker/hour-picker/hour-picker.component';
import { MonthPickerComponent } from 'src/app/shared/components/date-selector/date-picker/month-picker/month-picker.component';
import { WeekPickerComponent } from 'src/app/shared/components/date-selector/date-picker/week-picker/week-picker.component';
import { YearPickerComponent } from 'src/app/shared/components/date-selector/date-picker/year-picker/year-picker.component';
import { TimeframeToggleComponent } from 'src/app/shared/components/date-selector/timeframe-toggle/timeframe-toggle.component';
import { GlobalLoadingSpinnerComponent } from 'src/app/shared/components/global-loading-spinner/global-loading-spinner.component';
import { GraphComponent } from 'src/app/shared/components/graph/graph/graph.component';
import { HalfDonutComponent } from 'src/app/shared/components/half-donut/half-donut.component';
import { HelpButtonComponent } from 'src/app/shared/components/help-button/help-button.component';
import { LangSwitchComponent } from 'src/app/shared/components/lang-switch/lang-switch.component';
import { LineChartD3Component } from 'src/app/shared/components/line-chart/line-chart-d3/line-chart-d3.component';
import { LineChartComponent } from 'src/app/shared/components/line-chart/line-chart.component';
import { LinearGaugeComponent } from 'src/app/shared/components/linear-gauge/linear-gauge.component';
import { ListSkeletonComponent } from 'src/app/shared/components/list-skeleton/list-skeleton.component';
import { MetricSkeletonComponent } from 'src/app/shared/components/metric-skeleton/metric-skeleton.component';
import { MetricsBlocComponent } from 'src/app/shared/components/metrics-bloc/metrics-bloc.component';
import { NavButtonComponent } from 'src/app/shared/components/nav-button/nav-button.component';
import { PlaceChangeDialogComponent } from 'src/app/shared/components/place-selector/place-change-dialog/place-change-dialog.component';
import { PlaceSelectorComponent } from 'src/app/shared/components/place-selector/place-selector.component';
import { PremiumBadgeComponent } from 'src/app/shared/components/premium-badge/premium-badge.component';
import { SearchBoxComponent } from 'src/app/shared/components/search-box/search-box.component';
import { SerialInputDialogComponent } from 'src/app/shared/components/serial-input-dialog/serial-input-dialog.component';
import { SerialInputComponent } from 'src/app/shared/components/serial-input/serial-input.component';
import { TimezoneSelectComponent } from 'src/app/shared/components/timezone-select/timezone-select.component';
import { UnitSwitchComponent } from 'src/app/shared/components/unit-switch/unit-switch.component';
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';




@NgModule({
    declarations: [
        PlaceSelectorComponent,
        PlaceChangeDialogComponent,
        LangSwitchComponent,
        UnitSwitchComponent,
        AdminBarComponent,
        GlobalLoadingSpinnerComponent,
        MetricsBlocComponent,
        DateBlocComponent,
        SerialInputComponent,
        SerialInputDialogComponent,
        GraphComponent,
        GraphD3Component,
        NavButtonComponent,
        DateButtonComponent,
        DatePanelComponent,
        DatePickerComponent,
        DayPickerComponent,
        HourPickerComponent,
        MonthPickerComponent,
        YearPickerComponent,
        WeekPickerComponent,
        TimeframeToggleComponent,
        BarSkeletonComponent,
        MetricSkeletonComponent,
        ListSkeletonComponent,
        BubbleMapComponent,
        HelpButtonComponent,
        MetricsCardComponent,
        LineChartComponent,
        LineChartD3Component,
        HalfDonutComponent,
        LinearGaugeComponent,
        PremiumBadgeComponent,
        SearchBoxComponent,
        TimezoneSelectComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatTreeModule,
        MatDialogModule,
        MatButtonModule,
        MatMenuModule,
        MatTabsModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        MatCardModule,
        MatOptionModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatSidenavModule,
        FormsModule,
        InlineSVGModule,
        MatDatepickerModule,
        NgxMaterialTimepickerModule,
        MatButtonToggleModule,
        MatRippleModule,
        NgxSkeletonLoaderModule,
        LeafletModule,
        LeafletMarkerClusterModule,
        NgxMaskDirective
    ],
    exports: [
        PlaceSelectorComponent,
        AdminBarComponent,
        MetricsBlocComponent,
        DateBlocComponent,
        SerialInputComponent,
        GraphComponent,
        DateButtonComponent,
        UnitSwitchComponent,
        LangSwitchComponent,
        DatePickerComponent,
        TimeframeToggleComponent,
        DatePanelComponent,
        NavButtonComponent,
        GlobalLoadingSpinnerComponent,
        BarSkeletonComponent,
        MetricSkeletonComponent,
        ListSkeletonComponent,
        BubbleMapComponent,
        HelpButtonComponent,
        MetricsCardComponent,
        LineChartComponent,
        HalfDonutComponent,
        LinearGaugeComponent,
        PremiumBadgeComponent,
        SearchBoxComponent,
        TimezoneSelectComponent
    ],
    providers: [
        provideEnvironmentNgxMask()
    ]
})
export class SharedModule { }
