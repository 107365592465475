import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { AccountService } from 'src/app/core/services/accounts/account.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'hydrao-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {


  public forgotPassForm: UntypedFormGroup;
  public errorCode: string = null;
  public loading = false;
  private _email: string = null;


  constructor(
    private _logger: NGXLogger,
    private _accountService: AccountService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _utils: UtilsService

  ) { }

  ngOnInit() {
    this.forgotPassForm = new UntypedFormGroup({
      email: new UntypedFormControl({ value: this._email, disabled: false }, [Validators.required, Validators.email])
    });
  }


  public hasError(controlName: string, errorName: string) {
    return this.forgotPassForm.controls[controlName].hasError(errorName);
  }

  public async submit(formValue: any) {

    if (this.forgotPassForm.valid) {
      this.errorCode = null;
      this._logger.debug(`login with ${formValue.email}`);
      let email = formValue.email;
      try {
        // await lastValueFrom(this.accountService.registerUser(email, loginFormValue.pass));
        this.loading= true;
        this._accountService.forgotPassword(email).subscribe({
          next: () => {
            this._logger.info('forgo pass request successfull');
            let mailB64 = this._utils.toBase64Url(email);
            this._router.navigate(['/forgot-password-conf/' + mailB64]);
            this.loading = false;
          }, error: (err) => {
            if (err && err.error && err.error.error == 'UserNotFound') {
              this.forgotPassForm.controls.email.setErrors({ userNotFound: true })
            } else {
              this._snackBar.open($localize`unexpected error`, $localize`RETRY`, {
                panelClass: ['error-snackbar']
              }).onAction().subscribe(() => {
                this.submit(formValue);
              });
            }
            this.loading = false;
          }
        });

      } catch (err) {
        this.errorCode = err ? err.error ? err.error.error : null : null;
      };
    }
  }

  public goBack() {
    history.back();
  }




}
function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}