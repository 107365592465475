import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { CookieService } from "ngx-cookie-service";
import { INGXLoggerMonitor, INGXLoggerMetadata, INGXLoggerConfig, NgxLoggerLevel } from "ngx-logger";
import { lastValueFrom } from "rxjs";
import { LogService } from "src/app/core/services/log.service";
import { DbService } from "src/app/shared/services/db.service";
import { VERSION } from '../../environments/version';

interface ILogElt {
    key: string,
    value: string
}

@Injectable({ providedIn: 'root' })
export class HydraoLogMonitor implements INGXLoggerMonitor {

    private static _STORE_NAME = 'log';

    private _logFlushing = false;
    private _tempLogs: ILogElt[] = [];

    private _timeoutHandler: number = null;
    private _intervalHandler: number = null;

    private _admOnBehalf = null;

    constructor(
        private _logSvc: LogService,
        private _dbService: DbService,
        cookieService: CookieService) {
        console.log('log monitor loaded');

        window.onbeforeunload = () => {
            this._flushLog();
        };

        this._admOnBehalf = cookieService.get('admin_on_behalf');


    }

    public startLogFlushing() {
        if (this._timeoutHandler) clearTimeout(this._timeoutHandler);
        if (this._intervalHandler) clearInterval(this._intervalHandler);

        this._timeoutHandler = setTimeout(() => {
            this._flushLog();
        }, 500);

        this._intervalHandler = setInterval(() => {
            this._flushLog();
        }, 300000);
    }


    public onLog(logObject: INGXLoggerMetadata, config: INGXLoggerConfig): void {
        // console.log("myCustomLoggerMonitor", logObject);
        if (logObject.level >= NgxLoggerLevel.INFO) {
            let logMsg = `[${NgxLoggerLevel[logObject.level]}]`;
            logMsg += `[${logObject.timestamp}]`;
            logMsg += `[${logObject.fileName}:${logObject.lineNumber}]`;
            logMsg += ` ${logObject.message} ${logObject.additional ? logObject.additional.join(' ') : ''}`;
            if (this._admOnBehalf) {
                logMsg = `[ON-BEHALF=${this._admOnBehalf}]${logMsg}`;
            }

            this._writeToDb(logObject.timestamp, logMsg)
                .catch((e) => console.log('Error adding log to db ', (e && e.message ? e.message : JSON.stringify(e))));


        }
    }

    public async flushAndClean() {
        if (this._timeoutHandler) clearTimeout(this._timeoutHandler);
        if (this._intervalHandler) clearInterval(this._intervalHandler);
        await this._flushLog(true);
        localStorage.removeItem('log_flush_date');
        await this._dbService.clearAllCache();

    }

    private async _writeToDb(timestamp: string, message: string): Promise<void> {

        if (this._logFlushing) {
            this._tempLogs.push({ key: timestamp, value: message });
        }
        await this._dbService.writeLog(message, timestamp);
    }

    private async _flushLog(force = false) {
        // this._dbService.getAll(HydraoLogMonitor._STORE_NAME).subscribe((a) => {
        //     // console.log(a);
        // })


        if (this._logFlushing) return;
        let res: string[];
        if (!force) {
            let lastFlushDate = DateTime.fromISO(localStorage.getItem('log_flush_date'));
            if (!lastFlushDate.isValid) {
                lastFlushDate = DateTime.local();
                localStorage.setItem('log_flush_date', lastFlushDate.toISO());
            }
            let diffS = Math.round(DateTime.local().diff(lastFlushDate, ['seconds']).toObject().seconds);
            if (diffS < 300) {
                console.log(`[LOG] log flushed ${diffS} seconds ago, do nothing.`);
                return;
            }
            console.log(`[LOG] log flushed ${diffS} seconds ago ==> looking for log size `);
            res = await this._dbService.getAllLog();
            if (res.length <= 5) {
                console.log(`[LOG] log size is ${res.length} ==> exit. `);
            } else {
                console.log(`[LOG] log size is ${res.length} ==> proceed. `);
            }

        } else {
            console.log('[LOG] force flush');
            res = await this._dbService.getAllLog();

        }

        this._logFlushing = true;
        localStorage.setItem('log_flush_date', DateTime.local().toISO());





        if (res && res.length > 0) {
            let content = this._getIntro();
            content += '\n' + res.join('\n');

            await lastValueFrom(this._logSvc.sendLogs('my-' + this._getUniqueId(), content));
            await this._dbService.clearAllLog();
            if (!force) {
                let log: ILogElt;
                while (log = this._tempLogs.shift()) {
                    await this._dbService.writeLog(log.value, log.key);
                }
            }

            this._logFlushing = false;
        }


    }

    private _getUniqueId(): number {
        let hashCode = function (s: string) {
            var h = 0, l = s.length, i = 0;
            if (l > 0)
                while (i < l)
                    h = (h << 5) - h + s.charCodeAt(i++) | 0;
            return h;
        };
        let refreshToken = localStorage.getItem('refresh_token');

        return Math.abs(hashCode(refreshToken));
    }

    private _getIntro(): string {
        return `
            user agent : ${window.navigator.userAgent}
            resolution : ${window.innerWidth}x${window.innerHeight}
            version : ${VERSION.version} - ${VERSION.hash}
            `;

    }


}